import React, { Component } from 'react';
import { getLoadingTemplate } from '../../helpers/GlobalFunctions';
import ImageViewerComponent from '../../shared/ImageViewer';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';

export default class ScreenshotsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			currentImg: 0,
			pageSize: props.pageSize,
			rangeFrom: props.rangeFrom,
			rangeTo: props.rangeTo
		}
	}

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll); // attaching scroll event listener
	}

	/**
	 * @description Function to handle scrolling event
	 * @memberof ScreenshotsComponent
	 */
	/* istanbul ignore next  */
	handleScroll = () => {
		let userScrollHeight = window.innerHeight + window.scrollY;
		let windowBottomHeight = window.devicePixelRatio > 1 ? (window.screen.availHeight - 50) : document.documentElement.offsetHeight;
		if (userScrollHeight >= windowBottomHeight) {
			this.fetchNextSet();
		}
	}

	/**
	 * @description Function to fetch next set of images on scrolling
	 * @memberof ScreenshotsComponent
	 */
	/* istanbul ignore next  */
	fetchNextSet = () => {
		const { loading, error, pageSize, rangeTo, images, totalImages } = this.props;
		if (!loading && !error && images && images.length < totalImages) {
			let pageParams = {
				rangeFrom: rangeTo,
				rangeTo: rangeTo + pageSize
			}

			this.props.fetchNextSet(pageParams);
		}
	}

	/**
	 * @description Function to handle click on a thumbnail
	 * @param index Index of the selected image in the array
	 * @param event Click event
	 * @memberof ScreenshotsComponent
	 */
	onClickThumbnail = (index, event) => {
		this.setState({
			currentImg: index,
			isOpen: true
		})
	}

	/**
	 * @description Function to close the image viewer
	 * @memberof ScreenshotsComponent
	 */
	closeImageViewer = () => {
		this.setState({
			currentImg: 0,
			isOpen: false
		})
	}

	render() {
		const { loading, error, images } = this.props;
		return (
			<div className={images && images.length ? 'screenshots-wrap' : 'no-screenshots-wrap'}>

				{/* Screenshot thumbnails in a grid */}
				{images && images.length ?
					<>
						<div className="proms-block-title mb-3" data-testid="screenshots-component">Screenshots</div>
						<div className="row">
							{images.map((thumbnail, index) => (
								<div className="col-sm-3 mb-4" key={`thumb-${index}`}>
									<img alt={`Screenshot No. ${index + 1}`} id={`thumb-${index + 1}`} className="thumb-img" src={thumbnail} onClick={e => this.onClickThumbnail(index, e)} />
								</div>
							))}
						</div>

						<ImageViewerComponent
							images={images}
							isOpen={this.state.isOpen}
							currentImg={this.state.currentImg}
							closeImageViewer={this.closeImageViewer}
						/>
					</>
					: ''}

				{/* Loading template */}
				{loading ?
					<div className="row">
						<div className="col-sm-12">
							{getLoadingTemplate()}
						</div>
					</div>
					: ''}

				{/* Error handling and no data scenario */}
				{!loading ?
					error ? <ErrorMsgBlockComponent errorObject={error} /> :
						images && !images.length ? <ErrorMsgBlockComponent noData={true} /> : ''
					: ''}
			</div>
		)
	}
}
