import React, { Component } from 'react';
import { getMasterZip } from '../../services/java/java-services';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import DownloadError from './DownloadError';
import { loggerEventMessage, loggerEventName, loggerEventOutcome, loggerEventTypes, salesRepMessages } from '../../helpers/messages';
import ZipPassword from './ZipPassword';
import { getSessionStorage } from '../../services/storage/storage-service';
import { logger } from '../../services/logger/logger-service';
import { eventSource } from '../../helpers/constants';

export default class DownloadZip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            loading: false,
            showPassword: false,
            password: null,
            noRowSelected: [],
        };
    }

    componentDidMount() {
        sessionStorage.removeItem('selectedRow');
        window.addEventListener('storage', this.handleStorageChange);
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.handleStorageChange);
    }

    /**
     * @description function to check if there any plan selected not disable the button
     * @memberof DownloadZip
     */
    /* istanbul ignore next  */
    handleStorageChange = () => {
        const noRowSelected = JSON.parse(sessionStorage.getItem('selectedRow'));
        this.setState({ noRowSelected });
    };

    /**
     * @description Function to download file from backend
     * @param {Array} selectedRows - Array of selected files
     * @memberof DownloadZip
     */
    /* istanbul ignore next  */
    downloadFile = (selectedRows) => {
        const preopPlanIds = selectedRows.map((item) => item.VisionaierId);
        this.setState({ loading: true });
        // logs for download zips
        preopPlanIds?.forEach((number) => {
            const loggerObj = {
                EventOutcome: loggerEventOutcome.success,
                EventType: loggerEventTypes.create,
                EventName: loggerEventName.downloadZip,
                EventSource: eventSource.kpopPlans.value,
                Content: {
                    Data: loggerEventMessage.downloadZip(selectedRows[0].hospitalId),
                    CaseId: number.toString(),
                },
            };
            logger(loggerObj);
        });

        getMasterZip({ preopPlanIds: preopPlanIds.join(','), password: this.state.password, customerid: selectedRows[0].hospitalId }, (err, response) => {
            if (err) {
                this.setState({ error: err.message, loading: false });
            } else {
                const filename = response.config.params.customerid;
                this.setState({ error: null });
                this.downloadZipFile(response.data, filename);
            }
        });
    };

    /**
     * @description Function to handle the download click and check if the customer is same or not
     * @memberof DownloadZip
     */
    /* istanbul ignore next  */
    handleDownload = () => {
        let selectedPlans = JSON.parse(getSessionStorage('selectedRow')) || [];
        // check if there is record without valid hospitalId
        const noHospitalAssociated = selectedPlans.every((obj) => !obj.hospitalId);

        // check if plans selected are not from the same customer
        const hasSameHospitalId = selectedPlans.every((obj) => obj.hospitalId === selectedPlans[0].hospitalId);

        // if there is no hospital assigned to a plan and user tries to download the file show error invalid user
        if (noHospitalAssociated) {
            this.setState({ error: salesRepMessages.downloadErrorEmptyCust });
        } else {
            /* istanbul ignore next  */
            if (selectedPlans.length > 0) {
                if (hasSameHospitalId) {
                    this.setState({ showPassword: true });
                } else {
                    this.setState({ showModal: true });
                }
            } else {
                this.setState({ error: salesRepMessages.noPlans });
            }
        }
        /* istanbul ignore next  */
        setTimeout(() => {
            this.setState({ error: null });
        }, 3000);
    };

    /**
     * @description Function to download file from a html link in the page
     * @param {blob} blob - Blob for file
     * @param {fileName} fileName - File name for download
     * @memberof DownloadZip
     */
    /* istanbul ignore next  */
    downloadZipFile = async (response, filename) => {
        try {
            const url = URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filename}.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        } catch (error) {
            this.setState({ loading: false });
        }
    };

    /**
     * @description Function to close the modal
     * @memberof DownloadZip
     */
    /* istanbul ignore next  */
    closeModal = () => {
        this.setState({ showModal: false, showPassword: false });
    };

    /**
     * function to get password from the input for zip
     * @param {*} password
     */
   /* istanbul ignore next  */
    onPSubmit = (password) => {
        const selectedPlans = JSON.parse(getSessionStorage('selectedRow')) || [];

        this.setState({ password }, () => {
            this.downloadFile(selectedPlans);
        });
    };
  /* istanbul ignore next */
    render() {
        const { error, showModal, showPassword, loading } = this.state;
        const isDisabled = this.state?.noRowSelected?.length === 0 || loading;
        return (
            <>
                <div className='d-flex gap-2 align-items-center mt-3'>
                    {error && <p className='text-danger text-center w-100 mb-0'>{error}</p>}

                    <ErrorBoundary>
                        <DownloadError isVisible={showModal} closeModal={this.closeModal} />
                        <ZipPassword isPVisible={showPassword} hideModal={this.closeModal} loading={loading} onPSubmit={this.onPSubmit} />
                    </ErrorBoundary>
                    <button data-testid="download" disabled={isDisabled} className='btn btn-primary d-block mt-2 mb-2 ml-auto' id='download' onClick={this.handleDownload}>
                        {loading ? (
                            <>
                                <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
                            </>
                        ) : (
                            <svg className='bi bi-cloud-download' width='1.3em' height='1.3em' viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M4.887 5.2l-.964-.165A2.5 2.5 0 103.5 10H6v1H3.5a3.5 3.5 0 11.59-6.95 5.002 5.002 0 119.804 1.98A2.501 2.501 0 0113.5 11H10v-1h3.5a1.5 1.5 0 00.237-2.981L12.7 6.854l.216-1.028a4 4 0 10-7.843-1.587l-.185.96z' />
                                <path
                                    fillRule='evenodd'
                                    d='M5 12.5a.5.5 0 01.707 0L8 14.793l2.293-2.293a.5.5 0 11.707.707l-2.646 2.646a.5.5 0 01-.708 0L5 13.207a.5.5 0 010-.707z'
                                    clipRule='evenodd'
                                />
                                <path fillRule='evenodd' d='M8 6a.5.5 0 01.5.5v8a.5.5 0 01-1 0v-8A.5.5 0 018 6z' clipRule='evenodd' />
                            </svg>
                        )}
                    </button>
                </div>
            </>
        );
    }
}
