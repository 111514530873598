import React, { Component } from 'react'
import { getConcatenatedName, toTitleCase } from '../../helpers/GlobalFunctions';

export default class PatientDetails extends Component {
	render() {
		const { caseDetails, getUserRoleType } = this.props;

		return (
			<div className="patient-details" data-testid="patient-details-component">
				<div className="card">
				<div className="card-body">
					<h2 className="card-title">Patient Details</h2>
					{getUserRoleType.includes('rar')? '' :
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">Name</label>
							<div className="col-sm-8">
								<span className="form-control">
									{!caseDetails.patientFirstName && !caseDetails.patientMiddleName && !caseDetails.patientLastName ? 'N/A' :
										getConcatenatedName(caseDetails.patientFirstName, caseDetails.patientLastName, caseDetails.patientMiddleName)
									}
								</span>
							</div>
						</div>}
					<div className="form-group row">
						<label className="col-sm-4 col-form-label">Age</label>
						<div className="col-sm-8">
							<span className="form-control">{!caseDetails.patientage ? 'Unavailable' : caseDetails.patientage}</span>
						</div>
					</div>
					<div className="form-group row">
						<label className="col-sm-4 col-form-label">Sex</label>
						<div className="col-sm-8">
							<span className="form-control">{caseDetails.sex ? (caseDetails.sex.toString().toLowerCase() === "unidentified" ? "Unspecified" : toTitleCase(caseDetails.sex)) : 'N/A'}</span>
						</div>
					</div>
					<div className="form-group row">
						<label className="col-sm-4 col-form-label">BMI</label>
						<div className="col-sm-8">
							<span className="form-control">{!caseDetails.bMI ? 'N/A' : caseDetails.bMI}</span>
						</div>
					</div>
				</div>
				</div>
			</div>
		)
	}
}
