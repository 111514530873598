import React, { Component } from 'react';
import Select from 'react-dropdown-select';
import { Link } from 'react-router-dom';
import { formatDate, parseHTML, toTitleCase } from '../../helpers/GlobalFunctions';
import CustomModal from '../../shared/CustomModal';
import Header from '../Header/Header';
import Sidemenu from '../SideMenu/SideMenu';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock'
import { reviewCaseDetailsMessages } from '../../helpers/messages';

export default class ReviewCaseDetails extends Component {
    render() {
        const { loading, caseDetails, surgeons, selectedSurgeon, surgeonChanged, customers, disableSurgeons, disableCustomers, selectedCustomer, customerChanged,
            confirmSave, showModal, closeModal, saveDetails, error, errorMsg, saved, successMsg, saveError, saveErrorMsg, loggerObj, caseDetailsErr,
            showInactiveConfirmModal, inactiveDeviceParams, closeInactiveConfirmModal, inactiveDeviceConfirmed, inactiveDeviceError } = this.props;
        return (
            <>
                <div>
                    <Header />
                    <div className="bgGray">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-1">
                                    <Sidemenu loggerObj={loggerObj} />
                                </div>
                                <div className="col-md-11">
                                    <div className="file-list data-list-tabs review-case-details-wrap" data-testid="review-case-details">
                                        <div className="case-details-top-block align-items-center justify-content-between">
                                            <h1 className="case-details-heading">Review Case Details</h1>
                                            <div className="case-details-back-btn text-end">
                                                <Link className="btn btn-primary case-action-btn" id="view-case" to={"/reviewcases"}> Back to Review Cases</Link>
                                            </div>
                                        </div>
                                        {(saved && successMsg) || (saveError && saveErrorMsg) ?
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="my-2">
                                                        {saved && successMsg ? successMsg : ''}
                                                        {saveError && saveErrorMsg ? <ErrorMsgBlockComponent className="py-3 m-0" errorObject={saveErrorMsg} /> : ''}
                                                        {inactiveDeviceError ? <ErrorMsgBlockComponent className="py-3 m-0" errorObject={inactiveDeviceError} /> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            : ''}
                                        <div className="review-case-details-block case-details-container">
                                            {loading ? '' : caseDetails ?
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="review-case-details">
                                                            <div className="card-body">
                                                                <h2 className="card-title">Case Details</h2>
                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label">{caseDetails && caseDetails.patientId ? 'Patient ID' : 'Case ID'}</label>
                                                                    <div className="col-sm-8">
                                                                        <span className="form-control">{caseDetails && caseDetails.patientId ? caseDetails.patientId : caseDetails && caseDetails.caseId ? caseDetails.caseId : 'N/A'}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label">Date of Surgery</label>
                                                                    <div className="col-sm-8">
                                                                        <span className="form-control">{caseDetails && caseDetails.dateOfSurgery ? formatDate(caseDetails.dateOfSurgery) : 'N/A'}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label">Operative Side</label>
                                                                    <div className="col-sm-8">
                                                                        <span className="form-control">{caseDetails && caseDetails.operativeSide ? toTitleCase(caseDetails.operativeSide) : 'N/A'}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label">System SN</label>
                                                                    <div className="col-sm-8">
                                                                        <span className="form-control">{caseDetails && caseDetails.serialNo ? caseDetails.serialNo : 'N/A'}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label">Implant Design</label>
                                                                    <div className="col-sm-8">
                                                                        <span className="form-control">{caseDetails && caseDetails.finalImplantDesign ? caseDetails.finalImplantDesign : 'N/A'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="editable-fields">
                                                            <div className="row">
                                                                <div className="col-lg-12 surgeon-customer-select">
                                                                    <div className="card-body">
                                                                        <h2 className="card-title">Surgeon Details</h2>
                                                                        <div className="form-group row">
                                                                            <div className="col-sm-12">
                                                                                <Select
                                                                                    id="select-surgeon"
                                                                                    data-testid="select-surgeon"
                                                                                    className={!loading && !selectedSurgeon ? 'has-error' : ''}
                                                                                    placeholder="Search Surgeons"
                                                                                    disabled={disableSurgeons}
                                                                                    values={selectedSurgeon ? selectedSurgeon : []}
                                                                                    options={surgeons}
                                                                                    clearable={selectedSurgeon ? true : false}
                                                                                    searchBy="customName"
                                                                                    labelField="customName"
                                                                                    valueField="username"
                                                                                    onChange={(values) => surgeonChanged(values)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="card-body">
                                                                        <h2 className="card-title">Customer Details</h2>
                                                                        <div className="form-group row">
                                                                            <div className="col-sm-12">
                                                                                <Select
                                                                                    id="select-customer"
                                                                                    data-testid="select-customer"
                                                                                    className={!loading && !selectedCustomer ? 'has-error' : ''}
                                                                                    placeholder="Search Customers"
                                                                                    disabled={disableCustomers}
                                                                                    values={selectedCustomer ? selectedCustomer : []}
                                                                                    options={customers}
                                                                                    clearable={selectedCustomer ? true : false}
                                                                                    searchBy="customName"
                                                                                    labelField="customName"
                                                                                    valueField="customerid"
                                                                                    onChange={(values) => customerChanged(values)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="card-body py-0">
                                                                        <div className="form-group row">
                                                                            <div className="col-sm-8">
                                                                                {error && errorMsg ?
                                                                                    <p className="m-0 text-danger error-msg-block">{errorMsg}</p>
                                                                                    : ''}
                                                                            </div>
                                                                            <div className="col-sm-4 text-end">
                                                                                <button type="button" data-testid="save-details" id="save-details" disabled={saved} className="btn btn-primary" onClick={confirmSave}>Save</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Modal to confirm inactive device assignment */}
                                                        <CustomModal
                                                            id="confirm-inactive-device-modal"
                                                            show={showInactiveConfirmModal}
                                                            dialogClassName="confirm-inactive-device-dialog"
                                                            isConfirm={true}
                                                            title="Inactive Device Association"
                                                            body={parseHTML(reviewCaseDetailsMessages.confirmInactiveAssociation(inactiveDeviceParams))}
                                                            closeAction={closeInactiveConfirmModal}
                                                            rejectAction={closeInactiveConfirmModal}
                                                            confirmAction={inactiveDeviceConfirmed}
                                                        />
                                                        {/* Modal to confirm saving review details */}
                                                        <CustomModal
                                                            id="save-details-modal"
                                                            show={showModal}
                                                            isConfirm={true}
                                                            title="Case Review"
                                                            body={reviewCaseDetailsMessages.confirmSave}
                                                            closeAction={closeModal}
                                                            rejectAction={closeModal}
                                                            confirmAction={saveDetails}
                                                        />
                                                    </div>
                                                </div>
                                                : caseDetailsErr ? <ErrorMsgBlockComponent errorObject={caseDetailsErr} /> : <ErrorMsgBlockComponent noData={true} />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
