import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';

class AddCustomerComponent extends Component {
    constructor(props) {
        super(props);
        this.newCustomerRef = React.createRef();
    }
    render() {
        const {countryObj, country, stateObj, selectedState, dialCodeObj, countrydialcode, handleInputChange, saveCustomer, onEnterPress, formErrors, 
            cancelAction, showSuccessMsg, isLoading, customerSaveError} = this.props;
        return (
            <>
            <div>
                    <div className="row">
                        <div className="col-sm-12" data-testid="add-customer-form">
                                {showSuccessMsg ?
                                <div className="customer-save-success-msg">
                                    <p>{showSuccessMsg}</p>
                                </div>
                                : ''}
                            {customerSaveError ?
                                <div className="customer-save-error-msg">
                                    <ErrorMsgBlockComponent errorObject={customerSaveError} />
                                </div>
                                : ''}
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Customer Name<span className="red">*</span></label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        id="customername"
                                        data-testid="customername"
                                        autoComplete="off"
                                        className={`form-control ${formErrors && formErrors.customername ? 'has-error' : ''}`}
                                        onChange={(e) => handleInputChange('customername', e.target.value)}
                                        onKeyPress={ onEnterPress }
                                    />
                                    {formErrors && formErrors.customername ?
                                        <div className="customer-error-msg">
                                            <p>{formErrors.customername}</p>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Address Line 1<span className="red">*</span></label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        id="addressline1"
                                        data-testid="addressline1"
                                        autoComplete="off"
                                        className={`form-control ${formErrors && formErrors.addressline1 ? 'has-error' : ''}`}
                                        onChange={(e) => handleInputChange('addressline1', e.target.value)}
                                        onKeyPress={ onEnterPress }
                                    />
                                    {formErrors && formErrors.addressline1 ?
                                        <div className="customer-error-msg">
                                            <p>{formErrors.addressline1}</p>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Address Line 2</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        id="addressline2"
                                        data-testid="addressline2"
                                        autoComplete="off"
                                        className={`form-control ${formErrors && formErrors.addressline2 ? 'has-error' : ''}`}
                                        onChange={(e) => handleInputChange('addressline2', e.target.value)}
                                        onKeyPress={ onEnterPress }
                                    />
                                     {formErrors && formErrors.addressline2 ?
                                        <div className="customer-error-msg">
                                            <p>{formErrors.addressline2}</p>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Postal Code<span className="red">*</span></label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        id="postalcode"
                                        data-testid="postalcode"
                                        autoComplete="off"
                                        className={`form-control ${formErrors && formErrors.postalcode ? 'has-error' : ''}`}
                                        onChange={(e) => handleInputChange('postalcode', e.target.value)}
                                        onKeyPress={ onEnterPress }
                                    />
                                    {formErrors && formErrors.postalcode ?
                                        <div className="customer-error-msg">
                                            <p>{formErrors.postalcode}</p>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Country<span className="red">*</span></label>
                                <div className="col-sm-8">
                                    <select
                                        className={`form-select enabled-box ${formErrors && formErrors.country ? 'has-error' : ''}`}
                                        id="country"
                                        data-testid="country"
                                        value={country}
                                        onChange={(e) => handleInputChange('country', e.target.value)}
                                        onKeyPress={ onEnterPress }
                                    >
                                        <option value=''>Select Country</option>
                                        {countryObj ? countryObj.map((c, i) => (
                                            <option key={`country-${i}`} value={c.countryname}>{c.countryname}</option>
                                        )) : ''}
                                    </select>
                                    {formErrors && formErrors.country ?
                                        <div className="customer-error-msg">
                                            <p>{formErrors.country}</p>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">State<span className="red">*</span></label>
                                <div className="col-sm-8">
                                    <select
                                        className={`form-select enabled-box ${formErrors && formErrors.selectedState ? 'has-error' : ''}`}
                                        id="state"
                                        data-testid="state"
                                        value={selectedState}
                                        onChange={(e) => handleInputChange('selectedState', e.target.value)}
                                        onKeyPress={ onEnterPress }
                                    >
                                        <option value=''>Select State</option>
                                        {stateObj ? stateObj.map((s, i) => (
                                            <option key={`state-${i}`} value={s.stateName}>{s.stateName}</option>
                                        )) : ''}
                                    </select>
                                    {formErrors && formErrors.selectedState ?
                                        <div className="customer-error-msg">
                                            <p>{formErrors.selectedState}</p>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Country Dial Code<span className="red">*</span></label>
                                <div className="col-sm-8">
                                    <select
                                        className={`form-select enabled-box ${formErrors && formErrors.countrydialcode ? 'has-error' : ''}`}
                                        id="countrydialcode"
                                        data-testid="countrydialcode"
                                        value={countrydialcode}
                                        onChange={(e) => handleInputChange('countrydialcode', e.target.value)}
                                        onKeyPress={ onEnterPress }
                                        >
                                        <option value=''>Select Country Dial Code</option>
                                        {dialCodeObj ? dialCodeObj.map((d, i) => (
                                            <option key={`dail-${i}`} value={d}>{d}</option>
                                        )) : ''}
                                    </select>
                                    {formErrors && formErrors.countrydialcode ?
                                        <div className="customer-error-msg">
                                            <p>{formErrors.countrydialcode}</p>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Phone number<span className="red">*</span></label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        id="phonenumber"
                                        data-testid="phonenumber"
                                        autoComplete="off"
                                        className={`form-control ${formErrors && formErrors.phonenumber ? 'has-error' : ''}`}
                                        maxLength="10"
                                        onChange={(e) => handleInputChange('phonenumber', e.target.value)}
                                        onKeyPress={ onEnterPress }
                                    />
                                    {formErrors && formErrors.phonenumber ?
                                        <div className="customer-error-msg">
                                            <p>{formErrors.phonenumber}</p>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                            <div className="add-customer-actions-btn col-sm-12">
                                { isLoading ? <button className="btn btn-primary disabled">
                                        <span className="spinner-border spinner-border-sm"></span>
                                        &nbsp;Saving data...
                                      </button> : <button className="btn btn-primary proms-btn-color" type="button" id="saveCustomer" onClick={saveCustomer}>Save</button> }
                                <button className="btn btn-secondary add-customer-btn-cancel" type="button"  id="cancelsaveCustomer" onClick={cancelAction}>Cancel</button>
                            </div>
                        </div>
                    </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = (state) => (
    {
        state
    }
)
const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomerComponent);