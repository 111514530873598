import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../Header/Header';
import Sidemenu from '../SideMenu/SideMenu';
import { DataTable } from '../DataTable/DataTable';
import CaseFilterContainer from '../../containers/CaseFilter';
import CaseAnalyticsContainer from '../../containers/CaseAnalytics';
import ExportData from '../../containers/CaseList/ExportData';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { formatDate, toTitleCase } from '../../helpers/GlobalFunctions';
import { getUserRoleType } from '../../services/aws/aws-services';
import { operativeProcedureTypes } from '../../helpers/constants';
import { checkLocalStorage, getLocalStorage } from '../../services/storage/storage-service';
class CaseListComponent extends Component {
	constructor() {
		super();
		/* istanbul ignore next  */
		this.state = {
			showFilterBlock: false,
			loggedInUserType: getUserRoleType(),
			filterObj: checkLocalStorage('filterObjStorage') ? JSON.parse(getLocalStorage('filterObjStorage')) : ''
		}
	}

	/**
	 * @description function to get filter obj as props from filter component 
	 * @param {*}
	 * @memberof CaseListComponent
	 */
	getCaseFilterObj = (obj) => {
		this.setState({ filterObj: obj });
	}

	/**
	 * @description Function to get case ID for TKA and patient ID for THA for Case ID column
	 * @param data Row object with case data
	 * @returns Template with relevant data based on procedure type
	 * @memberof CaseListComponent
	 */
	/* istanbul ignore next  */
	getCaseIDColumnData = (data) => {
		if (!data.operativeProcedure) {
			return 'N/A';
		}
		const operativeProcedure = data.operativeProcedure.toLowerCase();
		switch (operativeProcedure) {
			case operativeProcedureTypes.TKA.inLowerCase:
			case operativeProcedureTypes.TKA.text.toLowerCase():
				return data.caseID ? <div title={data.caseID}>{data.caseID.substring(0, 5) + '...'}</div> : 'N/A';

			case operativeProcedureTypes.HIP.inLowerCase:
				let caseIDValue = data.patientID ? data.patientID : data.caseID ? data.caseID : null;
				return caseIDValue ? <div title={caseIDValue}>{caseIDValue.substring(0, 5) + '...'}</div> : 'N/A';

			default:
				return data.caseID ? <div title={data.caseID}>{data.caseID.substring(0, 5) + '...'}</div> : 'N/A';
		}
	}

	/**
	 * @description Function to get wrapper class for table for different users' view
	 * @returns String classname
	 * @memberof CaseListComponent
	 */
	/* istanbul ignore next  */
	getWrapperClass = () => {
		const { loggedInUserType } = this.state;
		if (loggedInUserType.includes('surgeon')) {
			return 'surgeon-view';
		} else if (loggedInUserType.includes('rar')) {
			return 'rar-view';
		} else if (loggedInUserType.includes('careteam')) {
			return 'careteam-view';
		} else {
			return 'surgeon-view';
		}
	}

	render() {
		const { handleTabSelect, activeTab, hiddenColumnsList, loggerObj } = this.props;
		const { loggedInUserType } = this.state;
		/* istanbul ignore next  */
		const columns = [
			{
				Header: 'Patient Name',
				accessor: 'caseDetails.patientName', // accessor is the "key" in the data,
				Cell: ({ row }) => row.original.caseDetails && row.original.caseDetails.patientName ? row.original.caseDetails.patientName : 'N/A',
				Filter: ''
			},
			{
				Header: 'Patient Sex',
				accessor: 'caseDetails.sex',
				Cell: ({ row }) => row.original.caseDetails.sex ? (row.original.caseDetails.sex.toString().toLowerCase() === "unidentified" ? "Unspecified" : toTitleCase(row.original.caseDetails.sex)) : 'N/A',
				Filter: ''
			},
			{
				Header: 'Age',
				accessor: 'caseDetails.patientage',
				Cell: ({ row }) => row.original.caseDetails && row.original.caseDetails.patientage ? row.original.caseDetails.patientage : 'N/A',
				Filter: ''
			},
			{
				Header: 'Case ID',
				accessor: 'caseDetails.caseID', // accessor is the "key" in the data,
				Cell: ({ row }) => row.original.caseDetails && row.original.caseDetails ? this.getCaseIDColumnData(row.original.caseDetails) : 'N/A',
				Filter: ''
			},
			{
				Header: 'Surgeon Name',
				accessor: 'caseDetails.surgeonName',
				Cell: ({ row }) => row.original.caseDetails && row.original.caseDetails.surgeonName ? row.original.caseDetails.surgeonName : 'N/A',
				Filter: '',
				hidden: true
			},
			{
				Header: 'Surgery Date',
				accessor: 'caseDetails.dateOfSurgery',
				Cell: ({ row }) => row.original.caseDetails && row.original.caseDetails.dateOfSurgery ? formatDate(row.original.caseDetails.dateOfSurgery) : 'N/A',
				Filter: ''
			},
			{
				Header: 'Procedure Type',
				accessor: 'caseDetails.operativeProcedure',
				Cell: ({ row }) => row.original.caseDetails && row.original.caseDetails.operativeProcedure ? (row.original.caseDetails.operativeProcedure.toString().toLowerCase() === operativeProcedureTypes.TKA.inLowerCase) ? operativeProcedureTypes.TKA.text : row.original.caseDetails.operativeProcedure : 'N/A',
				Filter: ''
			},
			{
				Header: 'Operative Side',
				accessor: 'caseDetails.operativeSide',
				Cell: ({ row }) => row.original.caseDetails && row.original.caseDetails.operativeSide ? toTitleCase(row.original.caseDetails.operativeSide) : 'N/A',
				Filter: ''
			},
			{
				Header: 'Implant',
				accessor: 'caseDetails.finalImplantDesign',
				Cell: ({ row }) => row.original.caseDetails && row.original.caseDetails.finalImplantDesign ? row.original.caseDetails.finalImplantDesign : 'N/A',
				Filter: ''
			},
			{
				Header: ' ',
				CaseAction: true
			}

		];
		return (
			<div>
				<Header />
				<div className="bgGray">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-1">
								<Sidemenu loggerObj={loggerObj} />
							</div>
							<div className="col-md-11">
								<div className="data-list-tabs caselist-tabs" data-testid="case-list">
									<CaseFilterContainer data-testid="case-filter" id="case-filter" userRoles={loggedInUserType.includes('rar') || loggedInUserType.includes('careteam')} setCaseFilterObj={this.getCaseFilterObj} />
									{this.state.filterObj && this.state.filterObj.proceduretype ? <ExportData filterObj={this.state.filterObj} /> : ''}

									<Tabs defaultActiveKey={activeTab} id="uncontrolled-tab-example" onSelect={handleTabSelect}>
										<Tab eventKey="caseList" title="Case List" className={`case-list-tab ${this.getWrapperClass()}`}>
											{activeTab === 'caseList' ? <DataTable listType="case-list" showPagination={true} columns={columns} filterObj={this.state.filterObj ? this.state.filterObj : ''} hiddenColumns={hiddenColumnsList} /> : null}
										</Tab>
										<Tab eventKey="Trends" className="trends-tab" title="TRENDS">
											{activeTab === 'Trends' ? <CaseAnalyticsContainer filterObj={this.state.filterObj} /> : null}
										</Tab>
									</Tabs>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => (
	{
		state
	}
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(CaseListComponent);
