import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_brokenAxis from "highcharts/modules/broken-axis";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportdata from "highcharts/modules/export-data";
import { graphContextMenuItems } from "../../helpers/constants";
import { exportAddOns } from "../../helpers/ChartExports";
import { logger } from '../../services/logger/logger-service';
import { loggerEventOutcome, loggerEventTypes, loggerEventName, noDataFound } from '../../helpers/messages';
let startDate;
HC_brokenAxis(Highcharts);
drilldown(Highcharts);
exporting(Highcharts);
exportdata(Highcharts);

class TimingDetails extends Component {
    constructor() {
        super();
        this.state = {
            pieChart: "",
            barChart: "",
            timingData: "",
        };
    }
    componentDidMount = async () => {
        this.createPieChart();
        this.createBarChart();
        // initialize the start date on page load
        startDate = new Date();
        window.addEventListener('beforeunload', this.applicationLogger);
    };

    /* istanbul ignore next  */
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.applicationLogger);
        this.applicationLogger();
    }
    /**
     * @description function to handle application logs
     * @param {*}
     * @memberof TimingDetails
     */
    /* istanbul ignore next  */
    applicationLogger = (key) => {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.caseSummeryTiming,
            "Content": {
                "TimeSpent": timeSinceLoad,
                "CaseId": this.props.caseId ? this.props.caseId : '',
            }
        }
        logger(loggerObj);
    }

    /**
     * @description function to check and create the values to be plotted in the pie chart
     * @param value Raw value coming from the API
     * @memberof TimingDetails
     */
    getPieChartPlotValues(value) {
        if (isNaN(parseFloat(value))) {
            return null;
        }

        return value;
    }

    /**
     * @description function to Create chart with Highcharts
     * @param {*}
     * @memberof TimingDetails
     */
    /* istanbul ignore next  */
    createPieChart() {
        var getTimingData = this.props.timingData;
        const getTimingDataDetailed = this.props.caseTimingData;
        if (getTimingData && Object.keys(getTimingData).length && getTimingDataDetailed) {
            // as per the requirement, Misc should be rolled into Setup
            if (getTimingData.setuptimePreference) {
                getTimingData.setup =
                getTimingData.setup +
                (getTimingData.misc ? getTimingData.misc : 0);
            }
            
            delete getTimingData.misc;
            // rearrange timing data by surgery order
            const timingDataBySurgeryOrder = {
                ...(getTimingData.setuptimePreference && {"Setup": this.getPieChartPlotValues(getTimingData.setup)}),
                "Registration": this.getPieChartPlotValues(getTimingData.registration),
                "Planning": this.getPieChartPlotValues(getTimingData.planning),
                "Cutting": this.getPieChartPlotValues(getTimingData.cutting),
                "PostOp": this.getPieChartPlotValues(getTimingData.postOp)
            };

            this.setState({ timingData: timingDataBySurgeryOrder });
            let timingData = [];
            var totalMin = 0;

            for (const [key, value] of Object.entries(timingDataBySurgeryOrder)) {
                var getMin = parseFloat(parseFloat(value / 60).toFixed(1));
                var getMinForSum = getMin && !isNaN(getMin) ? getMin : null;
                totalMin += getMinForSum ? getMinForSum : 0;
                
                var dataDict = {
                    name: key,
                    y: parseFloat(getMin),
                    drilldown: key,
                }; // Convert from second to minute
                timingData.push(dataDict);
            }

            let drilldownData = []; // this will be an array of dictionaries
            if (getTimingDataDetailed.states && getTimingDataDetailed.states.length) {
                for (let i = 0; i < getTimingDataDetailed.states.length; i++) {
                    let currDrilldownName = getTimingDataDetailed.group[i];
                    if (currDrilldownName === "Setup" && !getTimingData.setuptimePreference) {
                        continue
                    }
                    if (currDrilldownName === "Misc" && getTimingData.setuptimePreference) {
                        currDrilldownName = "Setup";
                    }
                    let drilldownInd = drilldownData.findIndex(
                        (drilldownObj) => drilldownObj.id === currDrilldownName
                    );
                    // if the category isn't already created, then create it
                    if (drilldownInd === -1) {
                        drilldownData.push({
                            name: currDrilldownName,
                            id: currDrilldownName,
                            borderWidth: 0.5,
                            dataLabels: {
                                connectorPadding: 3,
                                padding: -1,
                            },
                            data: [],
                        });
                        drilldownInd = drilldownData.length - 1; // Index of this new obj has to be the last one
                    }
                    // push data into that drilldownObj
                    // Raw value in seconds converted to minutes, rounding off to 1 decimal place
                    let drilldownTiming = parseFloat(parseFloat(getTimingDataDetailed.timing[i] / 60).toFixed(1));
                    drilldownData[drilldownInd].data.push([
                        getTimingDataDetailed.states[i],
                        this.getPieChartPlotValues(drilldownTiming), 
                    ]);
                }
            }

            let colours = ["#79c3ec", "#45b864", "#e2333b", "#a98bc0", "#f99d20"];
            if (!getTimingData.setuptimePreference) {
                colours.splice(0,1)
            }
            let self = this;
            /* istanbul ignore next  */
            var pieChartConfig = {
                exporting: {
                    sourceWidth: 900,
                    sourceHeight: 350,
                    buttons: {
                        contextButton: {
                            menuItems: graphContextMenuItems
                        },
                    },
                    chartOptions: {
                        chart: {
                            marginTop: 50,
                            marginBottom: 50,
                            spacingBottom: 60,
                            spacingRight: 10,
                            events: {
                                load: function () {
                                    exportAddOns(this);
                                }
                            }
                        },
                        title: {
                            y: 35,
                            style: {
                                fontSize: 12
                            }
                        },
                        legend: {
                            width: '13%',
                            y: 20
                        },
                        plotOptions: {
                            pie: {
                                size: 200,
                                innerSize: 100,
                                dataLabels: {
                                    connectorWidth: 0.5,
                                    x: 0,
                                    y: 4,
                                    style: {
                                        fontSize: 6,
                                    }
                                }
                            }
                        },
                    }
                },
                chart: {
                    type: "pie",
                    spacingRight: 50,
                    events: {
                        load: function () {
                            this.reflow();
                        },
                        drilldown: function (e) {
                            this.setTitle({text: e.point.name.toUpperCase() + "<br>" + parseFloat(e.point.y.toFixed(1)) + " Min", y:50 },true)                        },
                        drillup: function (e) {
                            if (e.seriesOptions.index === 0 &&  !getTimingData.setuptimePreference) {
                                setTimeout(() => {
                                    self.setState({ pieChart: {...self.state.pieChart,
                                        plotOptions: {
                                            pie: {
                                                center: ["50%", "50%"],
                                                colors:  ["#45b864", "#e2333b", "#a98bc0", "#f99d20"],
                                                innerSize: 150,
                                                showInLegend: true
                                            },
                                        }
                                    }
                                })
                                }, 500);
                            }
                            this.setTitle({ text: "TOTAL  <br>" + parseFloat(parseFloat(totalMin).toFixed(1)) + " Min", y:40 })
                        }
                    }
                },
                plotOptions: {
                    pie: {
                        center: ["50%", "50%"],
                        colors: colours,
                        innerSize: 150,
                        showInLegend: true
                    },
                },
                tooltip: {
                    enabled: true,
                    formatter: function () {
                        return (
                            "<b>" +
                            this.point.name +
                            "</b>: " +
                            parseFloat(parseFloat(this.point.y).toFixed(1)) +
                            " MINUTES (" +
                            Math.round(Highcharts.numberFormat(this.percentage, 2)) +
                            "%)"
                        );
                    },
                },
                legend: {
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "middle",
                    floating: true,
                    backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
                    borderWidth: 1
                },
                title: {
                    text: "TOTAL  <br>" + parseFloat(parseFloat(totalMin).toFixed(1)) + " Min",
                    align: "center",
                    verticalAlign: "middle",
                    y: 30,
                    x: 0,
                    style: {
                        fontWeight: "bold",
                    },
                },
                subtitle: {
                    text: "Case Time",
                    style: {
                        fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif",
                        color: '#333333',
                        fontSize: 18,
                        textAlign: 'center',
                    }
                },
                series: [
                    {
                        name: "Timing Data",
                        data: timingData,
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return this.point.y;
                            },
                        },
                    },
                ],
                drilldown: {
                    series: drilldownData,
                    drillUpButton: {
                        position: {
                            x: 0,
                            y: 0
                        }
                    }
                },
                responsive: {
                    rules: [{
                        condition: {
                            minWidth: 901,
                            maxWidth: 1200
                        },
                        chartOptions: {
                            legend: {
                                width: '12%'
                            }
                        }
                    }]
                }
            };

            this.setState({ pieChart: pieChartConfig });
        }
    }
    /* istanbul ignore next  */
    createBarChart() {
        const getTimingData = this.props.caseTimingData;
        if (getTimingData) {
            let distinctGroups = ['Setup', 'Registration', 'Planning', 'Cutting', 'PostOp', 'Misc'];

            if (!getTimingData.setuptimePreference) {
                distinctGroups.splice(0,1)
            }
            var breakArray = [];
            let series = [];
            var states = [];
            var colorList = {
                Cutting: "#a98bc0",
                PostOp: "#f99d20",
                Registration: "#45b864",
                Planning: "#e2333b",
                Setup: "#79c3ec",
                Misc: "#9FB05C",
            };
            distinctGroups.forEach(group => {
                if (group.toLowerCase() !== "misc") {
                    series.push({
                        name: group,
                        showInLegend: true,
                        color: colorList[group],
                        data: [],
                    });
                }
            });

            if (getTimingData.states) {
                for (var j = 0; j < getTimingData.states.length; j++) {
                    states.push(getTimingData.states[j]);
                    let timingGroupInstance = getTimingData.group[j];
                    let timingObj = {
                        name: getTimingData.states[j],
                        x: j,
                        y: parseFloat(parseFloat(getTimingData.timing[j] / 60).toFixed(1)),
                    };
    
                    if (getTimingData.group[j].toLowerCase() === "misc") {
                        series.forEach((x) => {
                            if (x.name.toLowerCase() === "setup") {
                                x.data.push(timingObj);
                            }
                        });
                    } else {
                        series.forEach(x => {
                            if (x.name === timingGroupInstance) {
                                x.data.push(timingObj);
                            }
                        });
                    }
                }
            }

            var barChartConfig = {
                exporting: {
                    sourceWidth: 900,
                    sourceHeight: 350,
                    buttons: {
                        contextButton: {
                            menuItems: graphContextMenuItems
                        },
                    },
                    chartOptions: {
                        chart: {
                            marginTop: 100,
                            marginBottom: 150,
                            events: {
                                load: function () {
                                    exportAddOns(this);
                                }
                            },
                        },
                        plotOptions: {
                            column: {
                                dataLabels: {
                                    style: {
                                        fontSize: '7px'
                                    }
                                }
                            }
                        }
                    }
                },
                chart: {
                    type: "column",
                    spacingRight: 50,
                },
                title: {
                    text: "Case Timing by States",
                },
                xAxis: {
                    categories: states,
                    title: {
                        text: "STATES",
                    },
                    tickmarkPlacement: 'on',
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: "TIME (minutes)",
                    },
                },
                legend: {
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "middle",
                    floating: false,
                    backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
                    borderWidth: 1,
                },
                plotOptions: {
                    column: {
                        pointWidth: 20,
                        minPointLength: 2,
                        grouping: false,
                        dataLabels: {
                            enabled: true,
                            style: {
                                color: '#000'
                            },
                            crop: false,
                            overflow: 'none'
                        },
                        events: {
                            legendItemClick: function () {
                                if (!this.visible) {
                                    // While showing
                                    let removeBreaks = [];
                                    breakArray.forEach((x, index) => {
                                        if (this.xData && this.xData.length) {
                                            this.xData.forEach(xData => {
                                                if (x.from === xData - 0.5 && x.to === xData + 0.5) {
                                                    // Getting the index of each break that needs to be removed
                                                    removeBreaks.push(index);
                                                }
                                            });
                                        }
                                    });

                                    // Sorting the indeces to be removed and then removing them from "breakArray" in a reverse order 
                                    // without interfering with the changing index after each splice
                                    removeBreaks.sort((a, b) => { return a - b; });
                                    for (var i = removeBreaks.length - 1; i >= 0; i--) {
                                        breakArray.splice(removeBreaks[i], 1);
                                    }
                                } else {
                                    // While hiding
                                    if (this.xData && this.xData.length) {
                                        this.xData.forEach(xData => {
                                            breakArray.push({
                                                from: xData - 0.5,
                                                to: xData + 0.5,
                                                breakSize: 0,
                                            });
                                        });
                                    }
                                }

                                this.chart.xAxis[0].update({
                                    breaks: breakArray,
                                });
                            },
                        },
                    },
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}: </span>',
                    pointFormat: '<span style="font-size:10px">{point.y:.1f}</span>',
                    shared: true,
                    useHTML: true,
                },
                series: series
            };

            this.setState({ barChart: barChartConfig });
        }
    }

    /**
     * @description function to get template when there is no data to display
     * @param {*}
     * @memberof TimingDetails
     */
    noDataTemplate(chartName) {
        return (
            <div className="chart-blocks">
                <h3 className="text-center">{chartName}</h3>
                <div className="text-center text-danger mb-4 mt-4">{noDataFound.noData}</div>
            </div>
        )
    }

    render() {
        const { caseTimingData, timingData } = this.props;

        return (
            <div>
                <div className="timing-data-display" data-testid="timing-details">
                    {this.state.pieChart && timingData && Object.keys(timingData).length ?
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.pieChart}
                            containerProps={{ style: { height: '400px' } }}
                        />
                        : this.noDataTemplate('Case Time')}
                </div>
                <div className="case-timing-data-display">
                    {this.state.barChart && caseTimingData && caseTimingData.states && caseTimingData.states.length ?
                        <div className="mt-2">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={this.state.barChart}
                                containerProps={{ style: { height: '400px' } }}
                            />
                        </div>
                        : this.noDataTemplate('Case Timing by States')}
                </div>
            </div>
        );
    }
}
export default TimingDetails;
