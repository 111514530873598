import React, { Component } from 'react';

export default class DeviceSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: ''
        }
    }
    /** 
     *  @description Function to input change
     *  @param searchString The value in the input field
     *  @memberof DeviceSearchComponent
     */
    handleOnChange = (searchString) => {
        this.setState({ searchString: searchString });
    }

    /** 
     *  @description Function to handle search reset
     *  @memberof DeviceSearchComponent
     */
    handleReset = () => {
        this.setState({ searchString: '' });
        this.props.handleReset();
    }

    /** 
     *  @description Function to handle search submit by hitting enter key
     *  @param e Keypress event
     *  @memberof DeviceSearchComponent
     */
    onEnterPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            this.props.handleSearchSubmit(this.state.searchString);
        }
    }

    render() {
        const { handleSearchSubmit } = this.props;
        const { searchString } = this.state;

        return (
            <div className="form-group pt-3 d-flex align-items-center justify-content-between m-0" data-testid="deviceSearch">
            <input type="text" data-testid="user-search" id="user-search" placeholder="Search for devices" className="form-control me-2"
                value={searchString} onChange={(e) => this.handleOnChange(e.target.value)} onKeyUp={this.onEnterPress} />
            <button type="button" data-testid="search-submit"id="search-submit" className="btn btn-primary me-2" disabled={searchString === ''} onClick={(e) => handleSearchSubmit(searchString)}>Search</button>
            <button type="button" data-testid="search-reset"id="search-reset" className="btn btn-secondary" onClick={this.handleReset}>Reset</button>
        </div>
        );
    }
}
