import React, { Component } from "react";
import { DataTable } from '../DataTable/DataTable';
import AddDeviceModalComponent from '../DeviceList/AddDeviceModal'
export default class CustomerDevice extends Component {
    render() {
        const { fromDashboard, searchSubmit, refreshDeviceList, deviceAdded, showSuccessMsg, customerid, isCustomerDisable, showDeviceBlock, togglePanels } = this.props;
        /* istanbul ignore next  */
        const columns = [
            {
                Header: 'Serial Number',
                accessor: 'serialno',
                Cell: ({ row }) => (<span>{row.original.serialno ? row.original.serialno : 'N/A'}</span>),
                Filter: ''
            },
            {
                Header: 'Status',
                deviceAction: true
            }
        ];
        return (
            <div className="card-body mt-4">
                <div className="d-flex align-items-center justify-content-between mb-2" data-testid="customer-device">
                    <h2 className="card-title pb-2 mb-0">Associated Devices</h2>
                    <AddDeviceModalComponent deviceAdded={deviceAdded} fromDashboard={fromDashboard} isCustomerDisable={isCustomerDisable} customerid={customerid} />
                </div>

                {showDeviceBlock ?
                    <>
                        <div className="customer-device-list" data-testid="showDeviceBlock">
                            <div className="table-wrapper table-wrap">
                                {showSuccessMsg ? <div className="proms-success-msg my-3" data-testid="showSuccessMsg">{showSuccessMsg}</div> : ''}
                                <DataTable
                                    listType="device-list"
                                    columns={columns}
                                    disableColmSort={true}
                                    showPagination={false}
                                    customerId={customerid}
                                    refreshDataList={searchSubmit || refreshDeviceList}
                                />
                            </div>
                        </div>
                        <div className="showText text-left mt-2">
                            <p className="m-0 text-primary d-inline-block fs-8-rem cursor-pointer" data-testid="device-panel-less" id="device-panel-less" onClick={(e) => togglePanels('device', e)}>Show Less</p>
                        </div>
                    </>
                    :
                    <div className="showText text-left mt-2">
                        <p className="m-0 text-primary d-inline-block fs-8-rem cursor-pointer" data-testid="device-panel-more" id="device-panel-more" onClick={(e) => togglePanels('device', e)}>Show More</p>
                    </div>}

            </div>
        );
    }
}
