import React, { Component } from 'react';
import { getImgSrc } from '../../helpers/GlobalFunctions';
import { triggerSyncService } from '../../services/java/java-services';

export default class HistorySyncButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            loading: 4,
            message: null,
        };
        this.resetState = this.resetState.bind(this);
    }

    /**
     * @description function to handle triggerSync
     * @memberof HistorySyncButton
     */

    triggerSync = () => {
        this.setState({ loading: 0 });

        setTimeout(() => {
            triggerSyncService((err, response) => {
                if (err) {
                    this.setState({ errorMessage: err.message, loading: 3 });

                    this.resetState();
                } else {
                    this.setState({ errorMessage: null, loading: 2, message: response?.data?.message });

                    this.resetState();
                }
            });
        }, 1000);
    };

    /**
     * @description function to reset state
     * @memberof HistorySyncButton
     */
    resetState() {
        setTimeout(() => {
            this.setState({ errorMessage: null, loading: 4, message: null });
        }, 5000);
    }

    render() {
        return (
            <div className='display-flex flex-column position-relative' data-testid="history-sync-button">
                <div className='sync sync-container '>
                    <button
                        disabled={this.state.loading === 0}
                        className='sync-inner-wrapper'
                        style={{
                            backgroundImage: `url(${getImgSrc('icons8-cloud-sync-50.png')})`,
                        }}
                        onClick={this.triggerSync}
                        data-testid="sync-button"
                    ></button>
                    <select data-testid="history-sync-select" id='history-sync-select' value='' className='sync-select' onChange={this.props.onLastSyncChange}>
                        <option></option>
                        <option className='option'>Last Sync</option>
                    </select>
                </div>
                <div className='setion-status p-2 text-right position-absolute sync-message'>
                    {this.state.loading === 2 && <p className='text-success'>{this.state?.message}</p>}
                    {this.state.loading === 3 && <p className='text-danger'>{this.state.errorMessage}</p>}
                </div>
            </div>
        );
    }
}
