import React, { Component } from 'react';
import { getSessionStorage, setSessionStorage } from '../../services/storage/storage-service';

export default class SelectAllRows extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: false,
        };
    }

    /**
     * @description to update the selected state on first mount
     * @memberof SelectAllRows
     */
    componentDidMount() {
        window.addEventListener('storage', () => this.updateSelectedState(this.props));
    }

    /**
     * @description to unmount and remove listener
     * @memberof SelectAllRows
     */
    componentWillUnmount() {
         /* istanbul ignore next  */
        window.removeEventListener('storage', () => this.updateSelectedState(this.props));
    }

    /**
     * @description get checkbox selected on update
     * @param {*} prevProps
     * @memberof SelectAllRows
     */
    componentDidUpdate(prevProps) {
        /* istanbul ignore next  */
        if (prevProps.table !== this.props.table) {
            this.updateSelectedState(this.props);
        }
    }

    /**
     * @description function to set the selected item in state and check if all selected
     * @param {*} props all columns of the table
     * @memberof SelectAllRows
     */
    updateSelectedState = (props) => {
        const { table } = props;
        const selectedRows = JSON.parse(getSessionStorage('selectedRow')) || [];

        const allItemsSelected = selectedRows.length > 0 && selectedRows.length === table.data.length;

        this.setState({ selected: allItemsSelected, tableData: allItemsSelected, isAllNull: this.isAllNotNull(table.data) });
    };

    /**
     * @description function to select all rows and deselect at once on click of checkbox
     * @memberof SelectAllRows
     */
    handleCheckboxChange = () => {
        const { table } = this.props;
        const { selected } = this.state;
        /* istanbul ignore next  */
        if (selected) {
            // Remove all rows from session storage
            sessionStorage.removeItem('selectedRow');
        } else {
            // Add all rows to session storage
            setSessionStorage('selectedRow', JSON.stringify(table.data));
        }
        const storageChangeEvent = new Event('storage');
        window.dispatchEvent(storageChangeEvent);
        this.setState({ selected: !selected });
    };

    /**
     * @description check if all hospital is null in the list
     * @memberof SelectAllRows
     */
    isAllNotNull = (obj) => {
        return obj.every(function (ob) {
            return ob.hospitalId === null;
        });
    };

    render() {
        const { selected } = this.state;

        return (
            <span>
                <input disabled={this.state.isAllNull} data-testid="selectRows" type='checkbox' className='form-check-input selectAllCheckbox' checked={selected} onChange={this.handleCheckboxChange} />
            </span>
        );
    }
}
