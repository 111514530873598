import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AlertMessage from '../AlertMessage/AlertMessage';
import { errorMessages } from '../../helpers/messages';
import CustomModal from '../../shared/CustomModal';
import ForgotPassword from '../../containers/ForgotPassword'
import { getImgSrc } from '../../helpers/GlobalFunctions';

class LoginComponent extends Component {
	render() {
		const { message, onSubmit, onInputChange, isLoading, onEnterPress, forgotPasswordModal, isShowForgotPwdModal, closeModal } = this.props;
		return (<div className="login-page">
			<div className="">
				<img src={getImgSrc('login-top.png')} alt="login" width="100%" height="280" />
			</div>
			<div className="home">
				<div className="container-fluid">
					<div className="container login-container" id="login">
						<div className="row">
							<div className="col-md-12">
								<form className="form-signin" onSubmit={onSubmit} >
									<div className="login_content">
										<div className="login-logo">
											<img src={getImgSrc('ri_insights_logo_caps.png')} alt="login logo" height="58" />
										</div>
										<div className="login-form-wrap d-flex flex-column align-items-between justify-content-between">
											{message ? <AlertMessage className="px-2" errorMessage={message} /> : null}
											<input
												type="text"
												id="inputEmail"
												className="form-control"
												value={this.props.username}
												onChange={(e) => onInputChange(e, 'username')}
												onKeyPress={onEnterPress}
												placeholder="Enter Username" />
											<input
												type="password"
												id="inputPassword"
												className="form-control"
												value={this.props.password}
												onChange={(e) => onInputChange(e, 'password')}
												onKeyPress={onEnterPress}
												placeholder="Enter Password" />
											<input type="hidden" name="App Version" value={process.env.REACT_APP_VERSION} />
											<div className="d-flex align-items-center justify-content-between" data-testid="loginBtn">
												<div className="forgot-password-text" data-testid="forgot-password-text" onClick={forgotPasswordModal}>{errorMessages.login.forgotPassward}</div>
												<button className="btn btn-lg btn-primary btn-block" id="loginBtn" type="button" onClick={onSubmit}>LOGIN</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
					{/* Modal for password reset */}
					<CustomModal
						id="forgot-password"
						data-testid="forgot-password"
						dialogClassName="forgot-password"
						show={isShowForgotPwdModal}
						isComponent={true}
						title="Forgot Password"
						closeAction={() => forgotPasswordModal()}>
						<ForgotPassword closeAction={closeModal} />
					</CustomModal>
				</div>
				{isLoading ? <div className="loading-overlay"><span>{errorMessages.login.logIn}</span></div> : null}
			</div>
		</div>);
	}
}

LoginComponent.propTypes = {
	onSubmit: PropTypes.func,
	message: PropTypes.any
};

const mapStateToProps = (state) => (
	{
		state
	}
)

const mapDispatchToProps = (dispatch) => ({

})


export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
