import React from 'react';
import { Collapse } from 'react-bootstrap';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      isDetailsOpen: false, // Track the open/closed state of the details section
    };
  }
  /* istanbul ignore next  */
  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
  }

  /* istanbul ignore next  */
  toggleDetails = () => {
    this.setState((prevState) => ({
      isDetailsOpen: !prevState.isDetailsOpen,
    }));
  };

  render() {
    const { hasError, error, errorInfo, isDetailsOpen } = this.state;
    const { componentName } = this.props;
    /* istanbul ignore next  */
    if (hasError) {
      return (
        <div className="alert alert-danger m-3" role="alert">
          <h4 className="alert-heading">Error</h4>
          <p>Something went wrong in the component: {componentName}</p>
          <hr />
          <p>Error message: {error && error.toString()}</p>
          <p>
            <button
              className="alert-heading"
              type="button"
              onClick={this.toggleDetails}
              aria-expanded={isDetailsOpen}
              aria-controls="errorDetails"
              data-testid="errorDetails"
            >
              Error details
            </button>
          </p>
          <Collapse in={isDetailsOpen}>
            <div id="errorDetails">
              <pre>{errorInfo && errorInfo.componentStack}</pre>
            </div>
          </Collapse>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
