import React, { Component } from 'react';
import Header from '../Header/Header';
import Sidemenu from '../SideMenu/SideMenu';
import PreOpFilterContainer from '../../containers/PreOpCaseListFilter/PreOpFilterContainer';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import CustomerSupportPreopPlanTable from './CustomerSupportPreopPlanTable';
import CustomModal from '../../shared/CustomModal';
import HistorySyncModal from './HistorySyncModal';
import PreOpPlanManualUpload from './PreOpPlanManualUpload';
import { loggerEventTypes, loggerEventName, loggerEventOutcome, loggerEventMessage } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
import { eventSource } from '../../helpers/constants';
import HistorySyncButton from './HistorySyncButton';
let startDate;

class CsPlanListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshDataList: false,
      allStatus: false,
      searchSubmit: false,
      showFilterBlock: false,
      filterObj: {
        status: 1,
        customer: 'all',
        procedureType: 'all',
        salesRep: 'all',
        searchString: '',
      },
      showHistorySyncModal: false,
      errorMessage: null,
      loading: false,
    };
  }

  /**
   * @description function to handle the refresh the review plan list
   * @memberof CsPlanListComponent
   */
  /* istanbul ignore next  */
  refreshTable = () => {
    this.setState({ refreshDataList: true });
  };

  /**
   * @description function to handle filter data to show in table by adding filter in obj
   * @param obj:Obj object contains info for configured filter
   * @memberof CsPlanListComponent
   */
  /* istanbul ignore next  */
  getPrePFilterObj = (obj) => {
    this.setState({ filterObj: obj, refreshDataList: true });
  };

  /**
   * @description function to Customer Support Show Last Sync Data
   * @param {*}
   * @memberof CsPlanListComponent
   */
  /* istanbul ignore next  */
  onLastSyncChange = () => {
    startDate = new Date();
    this.setState({ showHistorySyncModal: true });
  };

  /**
   * @description function to Customer Support Hide Last Sync Data and log action details
   * @param {*}
   * @memberof CsPlanListComponent
   */
  /* istanbul ignore next  */
  onHistorySyncClose = () => {
    this.setState({ showHistorySyncModal: false });
    this.lastSyncActionLogger();
  };

  /**
   * @description function to handle last sync action logs
   * @param {*}
   * @memberof CsPlanListComponent
   */
  /* istanbul ignore next  */
  lastSyncActionLogger = () => {
    // calculate the time since we loaded this page
    const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
    const loggerObj = {
      EventOutcome: loggerEventOutcome.success,
      EventType: loggerEventTypes.read,
      EventSource: eventSource.kpopPlans.value,
      EventName: loggerEventName.customerPreOpLastSyncAction,
      Content: {
        TimeSpent: timeSinceLoad,
        Data: loggerEventMessage.customerPreopLastSyncDetails,
      },
    };
    logger(loggerObj);
  };

  /**
   * @description function to show / hide filter block
   * @param {*}
   * @memberof CsPlanListComponent
   */
  /* istanbul ignore next  */
  showFilter = () => {
    this.setState((prevState) => ({
      showFilterBlock: !prevState.showFilterBlock,
      allStatus: false,
    }));
  };

  render() {
    const { isActiveAll } = this.props;
    return (
      <>
        <div>
          <Header />
          <div className='bgGray'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-1'>
                  <Sidemenu loggerObj={this.props.loggerObj} />
                </div>
                <div className='col-md-11 ' data-testid="csplanlist">
                  <div className='data-list-tabs caselist-tabs '>
                    <ErrorBoundary>
                      {!isActiveAll && (
                        <PreOpFilterContainer
                          id='pre-view-filter'
                          enableSalesRep={true}
                          setPreFilterObj={this.getPrePFilterObj}
                          filterObj={this.state.filterObj}
                          showFilterBlock={this.state.showFilterBlock}
                          showFilter={this.showFilter}
                          status={this.state?.filterObj?.status}
                        />
                      )}
                    </ErrorBoundary>
                    <div className='file-list p-3'>
                      <div className='d-flex align-items-center justify-content-between mb-negative-ten'>
                        <h1 className='file-list-heading ms-0 mt-0'>Pre-op Plans</h1>

                        <div className='d-flex gap-3 '>
                          
                           <PreOpPlanManualUpload  refreshData={this.refreshTable} />
                          <ErrorBoundary>{!isActiveAll && <HistorySyncButton onLastSyncChange={this.onLastSyncChange} />}</ErrorBoundary>
                        </div>
                      </div>
                      <ErrorBoundary>
                        <CustomerSupportPreopPlanTable
                          isUpdated={this.refreshTable}
                          listType='customer-support-preoplist'
                          showPagination={true}
                          filterObj={this.state.filterObj ? this.state.filterObj : ''}
                          showFilterBlock={this.state.showFilterBlock}
                          isActiveListOnly={isActiveAll}
                        />
                      </ErrorBoundary>

                      <ErrorBoundary>
                        <div className='customer-support-history-sync'>
                          {/* Modal to Show Customer Support Last History Sync Data*/}
                          <CustomModal
                            id='CustomerSupportLastSyncHistory'
                            data-testid="CustomerSupportLastSyncHistory"
                            dialogClassName='history-sync-dialog'
                            show={this.state.showHistorySyncModal}
                            isComponent={true}
                            title='Last Sync Details'
                            closeAction={this.onHistorySyncClose}
                            alertText='Close'
                            isAlert={true}
                          >
                            <HistorySyncModal />
                          </CustomModal>
                        </div>
                      </ErrorBoundary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default CsPlanListComponent;
