import React, { Component } from 'react';
import { DataTable } from '../DataTable/DataTable';
import { convertUTCToLocalDateTime, isDatePast, isValidDate } from '../../helpers/GlobalFunctions';
import MultiSelectComponent from './MultiSelectComponent';
import SelectAllRows from './SelectAllRows';
import { loggerEventMessage, loggerEventName, loggerEventOutcome, loggerEventTypes } from '../../helpers/messages';
import { eventSource } from '../../helpers/constants';
import { logger } from '../../services/logger/logger-service';
import IdleTimer from 'react-idle-timer';
import ExpirationTooltip from '../ExpirationTooltip/ExpirationTooltip';

export default class PreOpTableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allSelected: false,
            remaining: this.timeout,
            lastActive: new Date(),
            elapsed: 0,
            logSent: false,
            isMobile: window.innerWidth <= 1023, // to check device is mobile or not
            isIos: /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
            // we are using this because without this 1024 px was being treated as desktop but that is normal ipad pro size
        };
        this.idleTimer = null;
        this.timeout = 1000 * 60 * 10; // 10 minute timeout

    }

    shouldComponentUpdate(nextProps, nextState) {
        const { showFilterBlock } = this.props;
        const { showFilterBlock: nextShowFilterBlock } = nextProps;
        const { remaining, elapsed } = this.state;
        const { remaining: nextRemaining, elapsed: nextElapsed } = nextState;

        /* istanbul ignore next  */
        if (showFilterBlock !== nextShowFilterBlock || remaining !== nextRemaining || elapsed !== nextElapsed) {
            return false;
        }
        /* istanbul ignore next  */
        return true; // Allow re-rendering for other prop changes
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.timeSpentLogger);

        this.setState({
            remaining: this.idleTimer.getRemainingTime(),
            elapsed: this.idleTimer.getElapsedTime(),
        });
        /* istanbul ignore next  */
        this.timer = setInterval(() => {
            this.setState({
                remaining: this.idleTimer.getRemainingTime(),
                elapsed: this.idleTimer.getElapsedTime(),
            });
        }, 1000);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.timeSpentLogger);
        this.timeSpentLogger();
        clearInterval(this.timer);
    }

    /**
     * @description function to handle application logs for time spent on the page
     * @memberof PreOpTableComponent
     */
    /* istanbul ignore next  */
    timeSpentLogger = () => {
        // calculate the time since we loaded this page
        /* istanbul ignore next  */
        if (!this.state.logSent) {
            const timeSinceLoad = (new Date().getTime() - this.state.lastActive.getTime()) / 1000;

            const loggerObj = {
                EventOutcome: loggerEventOutcome.success,
                EventType: loggerEventTypes.read,
                EventName: loggerEventName.preOpList,
                EventSource: eventSource.kpopPlans.value,
                Content: {
                    Data: loggerEventMessage.preOpList,
                    TimeSpent: timeSinceLoad,
                },
            };
            this.setState({ logSent: true });
            logger(loggerObj);
        }
    };

    /**
     * @description Renders the eye icon for the expander cell in the table. The icon changes based on the row's expanded state.
     * @param {Object} row - The row data object.
     * @returns {JSX.Element} The JSX element representing the eye icon.
     * @memberof PreOpTableComponent
     */
    renderEyeIcon(row) {
        return (
            <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? (
                    <svg xmlns='http://www.w3.org/2000/svg' width='2.5em' height='2.5em' fill='#73BC51' className='bi bi-eye-fill' viewBox='0 0 16 16' data-testid="bi-eye-fill">
                        <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                        <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
                    </svg>
                ) : (
                    <svg width='2em' height='2em' viewBox='0 0 16 16' className='bi bi-eye-slash' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z' />
                        <path d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z' />
                        <path d='M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709z' />
                        <path fillRule='evenodd' d='M13.646 14.354l-12-12 .708-.708 12 12-.708.708z' />
                    </svg>
                )}
            </span>
        );
    }

    /**
     * @description Renders the cell content based on the provided accessor. If the value is present, it returns the value; otherwise, it returns 'N/A'.
     * @param {Object} row - The row data object.
     * @param {string} accessor - The property name to access the value from the row object.
     * @returns {string} The value from the row object based on the accessor or 'N/A' if not available.
     * @memberof PreOpTableComponent
     */
    renderCellContent(row, accessor) {
        return row.original[accessor]?.trim() ? row.original[accessor] : 'N/A';
    }

    /**
     * @description Renders the creation date cell in the table. It validates the date and converts it to local date-time format if valid.
     * @param {Object} row - The row data object containing the creation date.
     * @returns {string} The formatted creation date or 'Invalid Date'/'N/A' if the date is not valid or not available.
     * @memberof PreOpTableComponent
     */
    renderCreationDateCell(row) {
        const creationDate = row.original.creationdate;
        let validDate = 'Invalid Date';

        if (isValidDate(creationDate)) {
            validDate = convertUTCToLocalDateTime(creationDate);
        }
        const formattedDate = creationDate ? validDate : 'N/A';

        return (
            <div className='imp-col-width'>
                <span className='hide-column-on-mobile'>{formattedDate}</span>
                <span className={`show-on-mobile ${isDatePast()}`}>{formattedDate.substring(0, 10)}</span>
            </div>
        );
    }

    /**
     * @description Renders the expiration date cell in the table. It extracts the expiration date and passes it to the ExpirationTooltip component.
     * @param {Object} row - The row data object containing the expiration date.
     * @returns {JSX.Element} The ExpirationTooltip component with the expiration date or 'N/A' if the date is not available.
     * @memberof PreOpTableComponent
     */
    renderExpirationDateCell(row) {
        const expirationDate = row.original.expirationDate;
        const onlyDate = expirationDate ? expirationDate.substring(0, 10) : 'N/A';

        return <ExpirationTooltip hideToolTip={true} row={{ licenseExpiringInDays: row.original.expiringInDays, licenseExpireDate: onlyDate }} />;
    }

    render() {
        const { isUpdated, filterObj, searchString, status, isPending, showFilterBlock } = this.props;

        /* istanbul ignore next  */
        let columns = [
            {
                id: 'select',
                Header: (table) => <SelectAllRows table={table} />,
                Cell: ({ row }) => <MultiSelectComponent row={row.original} />,
            },
            {
                Header: () => <div className='col-width'> Plan Details</div>,
                id: 'expander',
                Cell: ({ row }) => this.renderEyeIcon(row),
                SubCell: () => null,
            },
            {
                Header: 'Creation Date',
                accessor: 'plan_creation_date',
                Cell: ({ row }) => this.renderCreationDateCell(row),
                Filter: '',
                disableSortBy: isPending  // if table is of pending cases type we don't need sorting
            },
            {
                Header: 'Pre-op Plan ID',
                accessor: 'case_number',
                Cell: ({ row }) => <div className='plan-col-width'>{this.renderCellContent(row, 'VisionaierId')}</div>,
                Filter: '',
                disableSortBy: isPending
            },
            {
                Header: 'Surgeon Name',
                accessor: 'surgeon_name',
                Cell: ({ row }) => <div className='sur-col-width'> {this.renderCellContent(row, 'SurgeonName')}</div>,
                Filter: '',
                disableSortBy: isPending
            },
            {
                Header: 'Hospital Association',
                reviewDropDown: true,
                accessor: 'hospitalAssociation',
            },
            {
                Header: 'Implant Type',
                accessor: 'operative_implant',
                Cell: ({ row }) => <div className='imp-col-width'> {this.renderCellContent(row, 'implantType')}</div>,
                Filter: '',
                disableSortBy: isPending
            },

            {
                Header: 'Status',
                reviewDropDown: true,
                accessor: 'procedureStatus',
            },
            {
                Header: 'Procedure Type',
                accessor: 'procedure_type',
                Cell: ({ row }) => <span>{row.original.patientDetails?.procedureType?.trim() || 'N/A'}</span>,
                Filter: '',
                disableSortBy: isPending
            },
            {
                Header: <div>Date Of Expiration</div>,
                Filter: '',
                accessor: 'expiration_date',
                Cell: ({ row }) => this.renderExpirationDateCell(row),
                disableSortBy: isPending
            },
        ];

        // new column for the mobile screen only responsiveness we don't need these columns in mobile
        const hiddenColumns = columns.filter((col) => col.accessor !== 'procedureStatus' && col.id !== 'select' && col.accessor !== 'lastUpdate' && col.accessor !== 'plan_creation_date');


        const { isMobile, isIos } = this.state
        //if it is for the pending page remove the select column
        /* istanbul ignore next  */
        if (isPending) {
            columns = columns.filter((column) => column.id !== 'select');
        }

        // object to handle the columns data dynamically we are hiding some fields in mobile
        const dataTableProps = {
            isUpdated,
            listType: 'review-plan-list',
            columns: isMobile || isIos ? hiddenColumns : columns,
            filterObj: filterObj || '',
            searchString: searchString || '',
            status,
            isPending,
            showFilterBlock,
        };

        return (
            <div>
                <IdleTimer
                    ref={(ref) => {
                        this.idleTimer = ref;
                    }}
                    timeout={this.timeout}
                    onIdle={this.timeSpentLogger}
                />


                {isIos ? (
                    <div className='show-on-mobile'>
                        <DataTable {...dataTableProps} showPagination={true} />
                    </div>
                ) : isMobile && (
                    <div className='show-on-mobile'>
                        <DataTable {...dataTableProps} showPagination={true} />
                    </div>
                )}
                {!isMobile && (
                    <div className='hide-column-on-mobile'>
                        <DataTable {...dataTableProps} showPagination={true} />
                    </div>
                )}


            </div>
        );
    }
}
