import React, { Component } from 'react'
import { CSVLink } from 'react-csv';
import CustomModal from '../../shared/CustomModal';
import AddCustomer from '../../containers/CustomerDetails/AddCustomer';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';
import CustomerSearch from './CustomerSearch'
import { getUserRoleType } from '../../services/aws/aws-services';
export default class CSVCustomer extends Component {
    constructor() {
        super()
        this.exportRef = React.createRef();
        this.state = {
            activeUserRole: getUserRoleType()
        }
    }

    /**
     * @description function to handle export action 
     * @param {*}
     * @memberof CSVCustomer
     */
    handleExportAction = () => {
        /* istanbul ignore next  */
        setTimeout(() => {
            this.exportRef.current.link.click();
            this.props.exportComplete();
        }, 1000);
    }

    render() {
        const { showAddCustomerModal, enableAddCustomerModal, exportCsvHeader, exportCustomerData, isExport, isLoading, exportToCsv, getCsvFileName, csvError, handleSearchSubmit, handleReset } = this.props;
        const isAdmin = this.state.activeUserRole.includes('admin')

        if (isExport) {
            this.handleExportAction();
        }
        return (
            <>
                {isAdmin ?
                    <div className="row">
                        <div className="col-md-12" data-testid="customer-list-csv">
                            <h1 className="file-list-heading">Customer List</h1>
                        </div>
                    </div>
                    : ''}
                <div className='row'>
                    <div className="col-md-6">
                        <CustomerSearch handleSearchSubmit={handleSearchSubmit} handleReset={handleReset} />
                    </div>
                    <div className="col-md-6" data-testid="loading">
                        {isLoading ?
                            <button className="btn btn-primary disabled add-customer-btn expor-customer-to-csv-btn">
                                <span className="spinner-border spinner-border-sm"></span>
                                &nbsp;Exporting...
                            </button> : <button type="button" data-testid="exportToExcel" id="exportToExcel" className="btn btn-primary add-customer-btn expor-customer-to-csv-btn" onClick={(e) => exportToCsv()}>Export to CSV</button>}
                        {exportCustomerData ? <CSVLink
                            headers={exportCsvHeader}
                            data={exportCustomerData}
                            filename={getCsvFileName}
                            ref={this.exportRef}
                            target="_blank" /> : null}
                        {isAdmin ? <button type="button" data-testid="addNewCustomer" id="addNewCustomer" className="btn btn-primary add-customer-btn" onClick={() => enableAddCustomerModal(true)}> Add New</button> : ''}
                    </div>
                    <div className="col-md-12">
                        {csvError ? <ErrorMsgBlockComponent errorObject={csvError} /> : ''}
                    </div>
                </div>

                <div className="add-surgeon-modal">
                    {/* Modal to add surgeon to mapping list */}
                    <CustomModal
                        id="addCustomerModal"
                        data-testid="addCustomerModal"
                        show={showAddCustomerModal}
                        isComponent={true}
                        title="Add Customer"
                        closeAction={() => enableAddCustomerModal(false)}>
                        <AddCustomer id="add-customer" cancelAction={() => enableAddCustomerModal(false)} />
                    </CustomModal>
                </div>
            </>
        )
    }
}

