import React, { Component } from 'react';
import { getImgSrc } from '../../helpers/GlobalFunctions';
import { SurgeonList } from '../Dashboard/RAR/SurgeonList';
class AssociatedCareteam extends Component {
  render() {
    const {handleInputChange, findCareTeam, formErrors, searchResults, isLoading, refreshDataList, onEnterPress, action, customer, searchError} = this.props;
    const columns = [
        {
          Header: 'Name',
          accessor: 'name',
          Filter: ''
        },
        {
          Header: 'Username',
          accessor: 'userName',
          Filter: ''
        },
        {
          Header: 'Email',
          accessor: 'email',
          Filter: ''
        },

        {
          Header: ' ',
          SearchCareteamAction: true
        }
      ]
    return (<div>
    <div className="surgeon-search-container" data-testid="careteam">
        <div className="row col-md-12">
            <div className="col-md-8">
                <input placeholder="Enter care team's member name" className="form-control" onKeyPress = {onEnterPress} type="text" data-testid="surgeonSearchKey" id="surgeonSearchKey" onChange={ (e) => handleInputChange(e.target.value)}/>
                {formErrors && formErrors.searchKey ? 
                <div className="proms-error-msg">
                    <p>{formErrors.searchKey}</p>
                </div>
                : ''}
            </div>
            <div className="col-md-4">
            <svg onClick = {findCareTeam} width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-search serach-icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
              <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
            </svg>
              {/* <button className="btn btn-primary proms-btn-color" onClick = {findSurgeons} id="findSurgeons">Search</button> */}
              </div>
        </div>
    </div>
    <div className="surgeon-search-results">
      {searchResults && isLoading === false ? <SurgeonList listType="careteam-search" error={searchError} action={action} customer={customer ? customer : ''} columns={columns} showPagination={false} disableColmSort={false} searchData={searchResults} refreshDataList={refreshDataList}/> : null} 
      {isLoading ? <div className="loading-img"><img src={getImgSrc('loading.gif')} alt="loading"/> </div> : null} 
    </div>
  </div>);
  }
}
export default AssociatedCareteam;

