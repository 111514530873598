import React, { Component } from "react";
import {
	convertUTCToLocalDateTime,
	isValidDate,
	getImgSrc,
} from "../../helpers/GlobalFunctions";
import CustomModal from "../../shared/CustomModal";
import { actions, errorMessages } from "../../helpers/messages";
import {
	uploadPreOpPlanZip,
	reviewPreOpPlanDetails,
	confirmUploadPreOpPlanZip,
} from "../../services/java/java-services";

export default class PreOpPlanManualUpload extends Component {
	constructor(props) {
		super(props);
		this.inputOpenFileRef = React.createRef();

		this.state = {
			showModal: false,
			selectedFile: null,
			errorMessage: "",
			isChecked: false,
			fileName: "",
			disclaimerCheckBox: "",
			isUploading: false,
			fileDataUploadConfirmation: false,
			uploadSuccess: false,
			preopCaseData: "",
			duplciatePreopPlan: false,
		};
	}

	// Helper to reset the form
	resetForm = () => {
		this.setState({
			errorMessage: "",
			selectedFile: null,
			fileName: "",
			isChecked: false,
			disclaimerCheckBox: "",
			isUploading: false,
			fileDataUploadConfirmation: false,
			uploadSuccess: false,
			preopCaseData: "",
			duplciatePreopPlan: false,
		});
	};

	showOpenFileDlg = () => this.inputOpenFileRef.current.click();

	openModal = () => {
		this.setState({
			showModal: true,
			errorMessage: "",
			selectedFile: null,
			fileName: "",
			isChecked: false,
			disclaimerCheckBox: "",
			isUploading: false,
			fileDataUploadConfirmation: false,
			uploadSuccess: false,
			preopCaseData: "",
			duplciatePreopPlan: false,
		})
	};

	closeModal = () => {
		if (this.state.isUploading) {
			return;
		}
		this.resetForm();
		this.setState({ showModal: false });
	};

	handleChange = (selectedFile) => {
		if (!selectedFile[0]) return;

		const file = selectedFile[0];
		const fileName = file.name;
		const fileSize = file.size;
		const maxFileSize = 5368709120; // 5GB

		if (fileName.split(".").pop().toLowerCase() !== "zip") {
			return this.setState({
				errorMessage: errorMessages.fileUpload.selectZip,
			});
		}

		if (fileSize > maxFileSize) {
			return this.setState({
				errorMessage: errorMessages.fileUpload.maxSizeAllowed,
			});
		}

		this.setState({ fileName, selectedFile: file, errorMessage: "" });
	};

	renderDate = (updatedDate, trimTime = false) => {
		if (!updatedDate || !isValidDate(updatedDate)) {
			return 'N/A';
		}
		let date = convertUTCToLocalDateTime(updatedDate);
		if (trimTime) {
			let dateArray = updatedDate.split('T');
			return dateArray?.length ? dateArray[0] : 'N/A';
		}
		return date;
	};

	getStatusLabel = (planStatus) => {
		switch (Number(planStatus)) {
			case 9:
				return 'Operation Complete';
			case 1:
				return 'Plan Available';
			case 2:
				return 'Plan Exported';
			default:
				return 'N/A';
		}
	};

	resetInput = (event) => {
		event.target.value = null;
		this.resetForm();
	};

	handleCheckboxChange = () => {
		this.setState((prevState) => ({
			isChecked: !prevState.isChecked,
			disclaimerCheckBox: !prevState.isChecked
				? ""
				: errorMessages.fileUpload.disclaimerCheckBox,
		}));
	};

	validateAndUpload = () => {
		const { isChecked, selectedFile } = this.state;
		if (isChecked && selectedFile) {
			this.setState({ isUploading: true });
			const formData = new FormData();
			formData.append("file", selectedFile);

			uploadPreOpPlanZip(formData, (err, result) => {
				if (!this.state.showModal) return

				if (err) {
					this.setState({ errorMessage: err.errorMessage || err.message, isUploading: false });
				} else {
					reviewPreOpPlanDetails(result.data, (err, resp) => {
						if (!this.state.showModal) return

						if (err) {
							this.setState({
								errorMessage: err.errorMessage || err.message,
								isUploading: false,
							});
						} else {
							const { preopCaseData, duplciatePreopPlan } = resp.data;
							this.setState({
								preopCaseData,
								duplciatePreopPlan,
								fileDataUploadConfirmation: true,
								isUploading: false
							});
						}
					});
				}
			});
		}
	};

	formatFullName(lastName, firstName, middleName) {
		let firstN = firstName?.trim() || 'Unknown';
		let middleN = middleName?.trim() || '';
		let lastN = lastName?.trim() || 'Unknown';
		return `${lastN}, ${firstN}${middleN && ','} ${middleN}`;
	}

	confirmUploadedData = () => {
		this.setState({ isUploading: true });
		confirmUploadPreOpPlanZip(
			{ ...this.state.preopCaseData },
			(err, result) => {
				if (!this.state.showModal) return

				if (err) {
					this.setState({ errorMessage: err.errorMessage, isUploading: false });
				} else {
					this.setState({
						isUploading: false,
						uploadSuccess: true,
					});

					setTimeout(() => {
						this.props.refreshData();
						this.closeModal();
					}, 5000);
				}
			}
		);
	};

	// Function to render the file upload form
	renderFileUploadForm() {
		const {
			fileName,
			errorMessage,
			isChecked,
			disclaimerCheckBox,
			selectedFile,
			isUploading,
		} = this.state;
		return (
			<>
				{isUploading ? <div className="loading-overlay manual-pre-op-upload-loader"><span>{actions.upload}</span></div> : ''}
				<div className="row">
					<div className="col">
						<div className="body body-section">
							<div className="file-upload-from-container mx-3">
								<div className="form-group w-100">
									<label htmlFor="inputFile">
										Selected File<span className="text-danger">*</span>:
									</label>
									<div className="input-group gap-2">
										<input
											type="file"
											ref={this.inputOpenFileRef}
											id="inputFile"
											data-testid="inputFile"
											onChange={(e) => this.handleChange(e.target.files)}
											onClick={this.resetInput}
											className="form-control file input-file d-none"
										/>
										<input
											placeholder="Select File"
											type="text"
											value={fileName}
											readOnly
											onClick={this.showOpenFileDlg}
											className="form-control input-file rounded disable-input"
											size="60"
										/>
										<div className="input-group-append">
											<button className="btn btn-secondary" onClick={this.showOpenFileDlg}>Browse</button>
										</div>
									</div>
									{errorMessage && <small className="text-danger">{errorMessage}</small>}
								</div>
							</div>

							<div className="file-upload-from-container mx-1">
								<div className="form-check">
									<input
										type="checkbox"
										id="disclaimerchk"
										data-testid="disclaimerchk"
										className="form-check-input"
										checked={isChecked}
										onChange={this.handleCheckboxChange}
									/>
									<label className="form-check-label text-justify" htmlFor="disclaimerchk">
										{errorMessages.fileUpload.check1}{" "}
										{errorMessages.fileUpload.check2}
										<span className="text-danger">*</span>
									</label>
									{disclaimerCheckBox && (
										<small className="text-danger">{disclaimerCheckBox}</small>
									)}
								</div>
							</div>

							<div className="d-flex justify-content-end gap-1 text-center mt-3">
								<button className="btn btn-light" onClick={this.closeModal} disabled={isUploading}>
									Cancel
								</button>
								<button
									className="btn btn-primary"
									id="fileUpload"
									data-testid="fileUpload"
									onClick={this.validateAndUpload}
									disabled={!isChecked || !selectedFile}>
									Upload
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	// Function to render the file upload confirmation
	renderFileDataUploadConfirmation() {
		const {
			preopCaseData,
			duplciatePreopPlan,
			uploadSuccess,
			errorMessage,
			isUploading,
		} = this.state;
		return (
			<div className="row">
				<div className="col">
					{!uploadSuccess && !errorMessage && (
						<div className="row justify-content-center">
							<div className="col-md-12">
								{duplciatePreopPlan && (
									<div className="alert alert-warning p-2" role="alert">
										<strong>Note:</strong>{" "}
										{errorMessages.preopPlans.preopPlanDuplicate}
									</div>
								)}

								<div className="card mb-2">
									<div className="card-header">
										<strong>Patient & Surgery Details</strong>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-md-3">
												<div className="mb-2">
													<label className="preop-info">
														<strong>Pre-op Plan ID:</strong>
													</label>
													<div className="preop-info">
														{preopCaseData?.caseNumber}
													</div>
												</div>
												<div className="mb-2">
													<label className="preop-info">
														<strong>Surgeon Name:</strong>
													</label>
													<div className="preop-info text-capitalize">
														{preopCaseData?.surgeonName}
													</div>
												</div>
												<div className="mb-2">
													<label className="preop-info">
														<strong>Sales Rep:</strong>
													</label>
													<div className="preop-info text-capitalize">
														{preopCaseData?.salesrepName}
													</div>
												</div>
												<div className="mb-2">
													<label className="preop-info">
														<strong>Hospital Association:</strong>
													</label>
													<div className="preop-info text-capitalize">
														{preopCaseData?.hospitalName}
													</div>
												</div>
											</div>

											<div className="col-md-3">
												<div className="mb-2">
													<label className="preop-info">
														<strong>Implant Type:</strong>
													</label>
													<div className="preop-info">
														{preopCaseData?.operativeImplant || 'N/A'}
													</div>
												</div>
												<div className="mb-2">
													<label className="preop-info">
														<strong>Status:</strong>
													</label>
													<div className="preop-info">
														{this.getStatusLabel(preopCaseData?.planStatus)}
													</div>
												</div>
												<div className="mb-2">
													<label className="preop-info">
														<strong>Procedure Type:</strong>
													</label>
													<div className="preop-info">
														{preopCaseData?.procedureName}
													</div>
												</div>
												<div className="mb-2">
													<label className="preop-info">
														<strong>Op Side & Condyle:</strong>
													</label>
													<div className="preop-info text-capitalize">
														{preopCaseData?.operativeSide}{" "}
														{preopCaseData?.operativeCondyle}
													</div>
												</div>
											</div>

											<div className="col-md-3">
												<div className="mb-2">
													<label className="preop-info">
														<strong>Patient Name:</strong>
													</label>
													<div className="preop-info text-capitalize">
														{this.formatFullName(preopCaseData?.patientLastName, preopCaseData?.patientFirstName, preopCaseData?.patientMiddleName)}
													</div>
												</div>
												<div className="mb-2">
													<label className="preop-info">
														<strong>Patient DOB:</strong>
													</label>
													<div className="preop-info">
														{this.renderDate(preopCaseData?.patientDOB, true)}
													</div>
												</div>
												<div className="mb-2">
													<label className="preop-info">
														<strong>Patient Sex:</strong>
													</label>
													<div className="preop-info text-capitalize">
														{preopCaseData?.gender}
													</div>
												</div>
												<div className="mb-2">
													<label className="preop-info">
														<strong>Org Hospital Info:</strong>
													</label>
													<div className="preop-info text-capitalize">
														{preopCaseData?.hospitalName}
													</div>
												</div>
											</div>

											<div className="col-md-3">
												<div className="mb-2">
													<label className="preop-info">
														<strong>Date of Expiration:</strong>
													</label>
													<div className="preop-info">
														{this.renderDate(preopCaseData?.expirationDate)}
													</div>
												</div>
												<div className="mb-2">
													<label className="preop-info">
														<strong>Surgery Date:</strong>
													</label>
													<div className="preop-info">
														{this.renderDate(preopCaseData?.surgeryDate, true)}
													</div>
												</div>
												<div className="mb-2">
													<label className="preop-info">
														<strong>Last Update:</strong>
													</label>
													<div className="preop-info">
														{this.renderDate(preopCaseData?.updatedDate)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					{uploadSuccess && (
						<p className="text-success px-4 pt-4">{errorMessages.preopPlans.manualUploadSuccess}</p>
					)}
					{errorMessage && (
						<p className="text-danger px-4 pt-4">{errorMessage}</p>
					)}

					<div className="d-flex justify-content-end gap-1 text-center mt-2">
						{
							!uploadSuccess && (
								<button className="btn btn-light" onClick={this.closeModal}>
									Cancel
								</button>
							)
						}
						{uploadSuccess && (
							<button
								className="btn btn-primary"
								type="button"
								onClick={this.closeModal}
							>
								Ok
							</button>
						)}
						{!uploadSuccess && (
							<button
								className="btn btn-primary"
								type="button"
								data-testid="confirm-btn"
								onClick={!uploadSuccess && this.confirmUploadedData}
								disabled={isUploading}
							>

								{isUploading ? (
									<span
										className="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									></span>
								) : (
									"Confirm"
								)}
							</button>
						)}
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { showModal, fileDataUploadConfirmation } = this.state;

		return (
			<div>
				<button data-testid="preopManualUpload"
					onClick={this.openModal}
					className='preop-upload-btn mt-1'
					style={{
						backgroundImage: `url(${getImgSrc('file-upload-white.png')})`,
					}}
				>
				</button>

				<CustomModal
					show={showModal}
					dialogClassName="overlapping-points-modal"
					isComponent={true}
					title="PreOp Plan Manual Upload"
					rejectAction={this.closeModal}
					closeAction={this.closeModal}
					hideClose={true}
				>
					{fileDataUploadConfirmation
						? this.renderFileDataUploadConfirmation()
						: this.renderFileUploadForm()}
				</CustomModal>
			</div>
		);
	}
}
