import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../../helpers/GlobalFunctions';
import { errorMessages } from '../../helpers/messages';
import SideMenu from '../SideMenu/SideMenu';

export default class ErrorComponent extends Component {
    render() {
        const { errorCode, message } = this.props;
        /* istanbul ignore next  */
        const isLoggedIn = isAuthenticated();
        return (
            <div className="bgGray">
                <div className={isLoggedIn ? 'container-fluid' : 'container-fluid error-page'}>
                    <div className="row">
                        {isLoggedIn ?
                            <div className="col-md-1">
                                <SideMenu />
                            </div>
                            : ''}
                        <div className={isLoggedIn ? 'col-md-11' : 'col-md-12 mt-3'}>
                            <div className={isLoggedIn ? 'data-list-tabs dashboard-blocks text-center p-4' : 'dashboard-blocks text-center p-4'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#db4518" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                </svg>
                                <h2 data-testid="errorMessage" className="my-2 display-1 text-muted">{errorCode || '404'}</h2>
                                <h5 className="mb-4 font-weight-normal">{message || errorMessages.pageNotFound}</h5>
                                <Link className="btn btn-primary my-2" to={isLoggedIn ? '/dashboard' : '/login'}>Back to {isLoggedIn ? 'Dashboard' : 'Login'}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
