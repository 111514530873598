import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import { formatDate, wholeNumberRoundOff } from '../../helpers/GlobalFunctions';
import { withRouter } from "react-router-dom";
import { graphContextMenuItems } from '../../helpers/constants';
import { exportAddOns } from '../../helpers/ChartExports';
import CustomModal from "../../shared/CustomModal";

class AnalyticsScatterChartTHA extends Component {
    constructor() {
        super();
        this.state = {
            scatterChartOptions: null,
            showModal: false,
            overlappingCases: []
        }
    }

    componentDidMount() {
        /* istanbul ignore next  */
        this.createChartWithHC();
    }

    /**
    * @description function to Create chart with Highcharts
    * @param {*}
    * @memberof AnalyticsScatterChartTHA
    */
    /* istanbul ignore next  */
    createChartWithHC() {
        const { xAxis, yAxis, showUnit, rangeUnit, lowUnit, highUnit, unit, analyticsData, chartDetails, spacingRight, legendMargin } = this.props;
        var getAnalyticsData = analyticsData ? analyticsData : {};
        const caseInfo = getAnalyticsData.caseInfo && getAnalyticsData.caseInfo.length ? getAnalyticsData.caseInfo : [];
        var setAnalyticsData = [];
        var xMin = 50;
        var xMax = 90;

        if (getAnalyticsData.series && getAnalyticsData.series.length) {
            const xAxisData = getAnalyticsData.series.find(x => x.title === xAxis);
            const yAxisData = getAnalyticsData.series.find(y => y.title === yAxis);
            var dataArray = [];
            if (xAxisData && xAxisData.data && xAxisData.data.length && yAxisData && yAxisData.data && yAxisData.data.length) {
                // Setting the min and max for X axis to the closest multiple of 2
                var xAxisFilteredDataset = xAxisData.data.filter(x => !isNaN(parseFloat(x)))
                xMin = Math.floor(Math.min(...xAxisFilteredDataset) / 2) * 2;
                xMax = Math.ceil(Math.max(...xAxisFilteredDataset) / 2) * 2;

                for (let i = 0; i < xAxisData.data.length; i++) {
                    if (xAxisData.data[i] && yAxisData.data[i]) {
                        const dataPoint = {
                            x: wholeNumberRoundOff(xAxisData.data[i], true),
                            y: wholeNumberRoundOff(yAxisData.data[i], true),
                            caseInfo: caseInfo[i]
                        }
                        dataArray.push(dataPoint);
                    }
                }
                let dataObj = {
                    name: chartDetails.legend,
                    color: chartDetails.color,
                    data: dataArray
                }
                setAnalyticsData.push(dataObj);
            }
        }

        const scatterChartOptions = {
            exporting: {
                sourceWidth: 900,
                sourceHeight: 350,
                buttons: {
                    contextButton: {
                        menuItems: graphContextMenuItems
                    }
                },
                csv: {
                    columnHeaderFormatter: function (item, key) {
                        if (!item || item instanceof Highcharts.Axis) {
                            return item.options.title.text;
                        }

                        switch (key) {
                            case 'x':
                                return  chartDetails?.labelXTitle.toUpperCase() || key;
                            case 'y':
                                return  chartDetails?.labelYTitle.toUpperCase() || key;
                        
                            default:
                                return key;
                        }
                    },
                },
                chartOptions: {
                    chart: {
                        marginTop: 100,
                        marginBottom: 80,
                        events: {
                            load: function () {
                                if (this.options.chart.forExport) {
                                    this.update({ chart: { spacingRight: spacingRight + 20 } })

                                    if (this.xAxis && this.xAxis.length) {
                                        this.xAxis[0].update({
                                            labels: {
                                                formatter: (event) => {
                                                    return `<span>${event.value}</span>`;
                                                },
                                            }
                                        });
                                    }
                                }
                                exportAddOns(this);
                            }
                        }
                    }
                }
            },
            chart: {
                type: 'scatter',
                zoomType: 'xy',
                spacingRight: spacingRight,
                events: {
                    load: function () {
                        if (this.yAxis && this.yAxis.length) {
                            var yAxisTitleMargin = 10;
                            const maxLabelLength = this.yAxis[0].paddedTicks && this.yAxis[0].paddedTicks.length ? Math.max(...(this.yAxis[0].paddedTicks.map(tick => tick.toString().length))) : 4;
                            if (maxLabelLength >= 4) {
                                yAxisTitleMargin = 0;
                            }
                            if (maxLabelLength === 3) {
                                yAxisTitleMargin = 6;
                            }
                            if (maxLabelLength < 2) {
                                yAxisTitleMargin = 17;
                            }
                            this.yAxis[0].update({
                                title: {
                                    margin: yAxisTitleMargin
                                }
                            });
                        }

                        this.reflow();
                    }
                }
            },
            title: {
                text: chartDetails ? chartDetails.graphTitle : ''
            },
            xAxis: {
                min: xMin,
                max: xMax,
                tickInterval: 2,
                startOnTick: true,
                title: {
                    enabled: true,
                    text: chartDetails ? chartDetails.labelXTitle.toUpperCase() : ''
                }
            },
            yAxis: {
                title: {
                    text: chartDetails ? chartDetails.labelYTitle.toUpperCase() : ''
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                floating: false,
                backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
                borderWidth: 1,
                margin: legendMargin
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: (event) => {
                                const point = event.point;
                                const series = point.series;
                                let overlappingCases = point && point.caseInfo ? [point.caseInfo] : []
                                // Check if two points are overlapping. Cannot redirect on overlapping points.
                                if (series.data && series.data.length) {
                                    for (let index = 0; index < series.data.length; index++) {
                                        const dataPoint = series.data[index];
                                        if (dataPoint.x === point.x && dataPoint.y === point.y && index !== point.index) {
                                            overlappingCases.push(dataPoint.caseInfo)
                                        }
                                    }
                                }

                                if (overlappingCases.length > 1) {
                                    this.openModal(overlappingCases);
                                } else {
                                    this.individualCaseSummary(point.caseInfo);
                                }
                            }
                        }
                    }
                },
                scatter: {
                    lineWidth: 0,
                    marker: {
                        radius: 5,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                }
            },
            tooltip: {
                formatter: function () {
                    const getTooltip = (xValue, yValue, seriesName, caseDetails) => {
                        const unitToShow = rangeUnit ? ((Math.sign(yValue) === -1) || ((1 / Math.sign(yValue)) === -Infinity) ? lowUnit : highUnit) : (unit ? unit : '');
                        const xVal = Math.abs(xValue); // Taking absolute x value from the plotted point
                        const yVal = Math.abs(yValue); // Taking absolute y value from the plotted point
                        let introText = caseDetails && caseDetails.patientId ? caseDetails.patientId : seriesName;
                        return `<div style="{border-bottom: 1px solid #ccc}">
                                    <strong>Patient ID: ${introText}</strong><br/> 
                                    <strong>${xVal}${showUnit ? unitToShow : ''}</strong> ${xAxis ? xAxis : ''}, <strong>${yVal}${showUnit ? unitToShow : ''}</strong> ${yAxis ? yAxis : ''}<br/>
                                </div>`;
                    }

                    const series = this.series;
                    const point = this.point;
                    const hoveredPoint = { x: this.x, y: this.y, index: point.index };
                    var tooltip = getTooltip(hoveredPoint.x, hoveredPoint.y, series, point.caseInfo);
                    if (series.data && series.data.length) {
                        series.data.forEach((dataPoint, index) => {
                            if (dataPoint.x === hoveredPoint.x && dataPoint.y === hoveredPoint.y && index !== hoveredPoint.index) {
                                tooltip += `<br/>${getTooltip(dataPoint.x, dataPoint.y, series.name, dataPoint.caseInfo)}`;
                            }
                        })
                    }

                    return tooltip;
                }
            },
            series: setAnalyticsData
        };
        this.setState({ scatterChartOptions: scatterChartOptions });
    }

    /**
    * @description Function to redirect to case details page on data point and label click
    * @param caseInfo Details of the case clicked
    * @memberof AnalyticsScatterChartTHA
    */
    /* istanbul ignore next  */
    individualCaseSummary = (caseInfo) => {
        this.props.history.push({
            pathname: `/casedetails/${caseInfo.caseId}`,
            state: {
                id: caseInfo.caseId,
                surgeonName: caseInfo.surgeonname,
                activeTab: 'Trends'
            }
        })
    }

    /**
    * @description Function to show modal when an overlapping point is clicked
    * @param overlappingCases List of all overlapping cases
    * @memberof AnalyticsScatterChartTHA
    */
    /* istanbul ignore next  */
    openModal = (overlappingCases) => {
        this.setState({ showModal: true, overlappingCases });
    }

    /**
    * @description Function to close modal
    * @memberof AnalyticsScatterChartTHA
    */
    /* istanbul ignore next  */
    closeModal = () => {
        this.setState({ showModal: false, overlappingCases: null });
    }

    render() {
        const { analyticsData } = this.props;
        const { scatterChartOptions, showModal, overlappingCases } = this.state;
        return (<div className="chart-blocks" data-testid="analytics-scatter-chart">
            {analyticsData && scatterChartOptions ?
                <div className="gaps-container-highchart" >
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={scatterChartOptions}
                        containerProps={{ style: { height: '400px' } }}
                    />

                    {/* Modal to decide redirection for multiple overlapping points */}
                    <CustomModal show={showModal} dialogClassName="overlapping-points-modal" isComponent={true} title="Please Select A Case To View" closeAction={this.closeModal}>
                        <div className="row">
                            <div className="col-md-12">
                                {overlappingCases && overlappingCases.length ?
                                    <table className="table overlapping-cases">
                                        <thead>
                                            <tr>
                                                <th scope="col">Patient ID</th>
                                                <th scope="col">Surgery Date</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {overlappingCases?.map((x, index) =>
                                                <tr key={`case-${index + 1}`}>
                                                    <td>{x.patientId}</td>
                                                    <td>{formatDate(x.dateOfSurgery)}</td>
                                                    <td><button id={`details-btn-${index + 1}`} className="btn btn-sm-primary" onClick={(e) => { e.preventDefault(); this.individualCaseSummary(x); }}>View Details</button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    : ''}
                            </div>
                        </div>
                    </CustomModal>
                </div>
                : null}
        </div>);
    }
}
export default withRouter(AnalyticsScatterChartTHA);
