import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { convertUTCToLocalDateTime } from '../helpers/GlobalFunctions';
import { commentsEditorConfig } from '../containers/CaseDetails/constants';

export default class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditMode: false,
            isLoading: false,
            editorInstance: null,
            wordCount: 0,
            charsLeft: props.maxCharCount,
            error: {
                maxCharCountError: false
            },
            editorData: props.data || ''
        }
    }

    componentDidMount() {
        // this.setState({ editorInstance: CKEditor.instances.ck_editor_name })
    }

    /* istanbul ignore next  */
    componentDidUpdate(prevProps) {
        if (prevProps.saved === true && this.state.isEditMode) {
            this.setState({
                isEditMode: false,
                isLoading: false,
                editorData: this.props.data
            });
        }
    }

    /** @description function to convert the text content of the HTML output from the editor to a string
     *  @param  {str} // the text content of the HTML output from the editor
     *  @memberof TextEditor
     */
    /* istanbul ignore next  */
    getText(str) {
        var tmp = document.createElement('div');
        tmp.innerHTML = str.toString().trim();
        return tmp.innerText ? tmp.innerText : str;
    }

    /** @description function to handle every input change in the editor and calculate number of characters typed
     *  @param  {editor} // editor instance bound to this component instance
     *  @memberof TextEditor
     */
    /* istanbul ignore next  */
    handleInputChange = (event, editor) => {
        if (!editor) return;

        const inputText = this.getText(editor.getData()) || '';
        const charLeft = this.props.maxCharCount - inputText.length;

        this.setState({
            error: {
                maxCharCountError: inputText.length > this.props.maxCharCount
            },
            editorInstance: editor,
            editorData: editor.getData(),
            wordCount: inputText.split(' ').length,
            charsLeft: charLeft,
            charsExceedBy: inputText.length - this.props.maxCharCount
        });
    }

    /** @description function to handle edit/cancel button actions
     *  @param  {}
     *  @memberof TextEditor
     */
     /* istanbul ignore next  */
    toggleEditMode = () => {
        if (!this.state.isEditMode) {
            this.props.handleNotesInputChange();
        }
        const updatedEditorData = this.state.isEditMode ? this.props.data : this.state.editorData;
        if (this.state.editorInstance) {
            this.state.editorInstance.setData(updatedEditorData);
        }

        this.setState(prevState => {
            return {
                isEditMode: !prevState.isEditMode,
                isLoading: false,
                editorData: updatedEditorData
            }
        });
    }
    submit = () => {
        if (!this.state.editorInstance) return;

        const editorData = this.state.editorInstance.getData();
        this.setState({ isLoading: true,editorData: editorData,isEditMode: false,  });
        this.props.submit(editorData, this.state.error.maxCharCountError);
     }

    render() {
        const { maxCharCount, isEditDisabled, height, placeholder, notesUpdatedDate, notesUpdatedBy } = this.props;
        const { isEditMode, error } = this.state;

        return (
            <div className="editor-wrapper" data-testid="text-editor">
              {  /* istanbul ignore next  */}
                <CKEditor
                    data-testid="ck_id"
                    editor={ClassicEditor}
                    data={this.state.editorData}
                    disabled={!isEditMode}
                    onReady={editor => {
                        this.setState({ editorInstance: editor })
                         // Automatically adjust the default height of the editor 
                         editor.editing.view.change((writer) => {
                            writer.setStyle(
                                "height",
                                "200px",
                                editor.editing.view.document.getRoot()
                            );
                        });
                        editor.editing.view.document.on("clipboardInput", (evt, data) => {
                            const pastedHtml = data.dataTransfer.getData("text/html");
                            const containsImg = /<img\s[^>]*src="[^"]*"[^>]*>/g.test(
                              pastedHtml
                            );
                            if (containsImg) {
                              evt.stop();
                              return;
                            }
                          });
                    }}
                    onChange={(event, editor) => this.handleInputChange(event, editor)}
                    config={commentsEditorConfig({ height, placeholder })}
                />
                <div className="mt-2 editor-footer d-flex justify-content-between align-items-center">
                    <div className="error-block">
                        {error.maxCharCountError && <div id="err-msg" className="error">You have exceeded the maximum limit of {maxCharCount} characters.</div>}
                    </div>
                    <div className="word-count">
                        <strong>Characters Remaining: </strong>{this.state.charsLeft}
                    </div>
                </div>
                <div className="editor-footer d-flex justify-content-between align-items-center mt-3">
                    <div className="last-updated-text">
                        {notesUpdatedBy ?
                            <div className='button-wrap'>Updated by : <span>{notesUpdatedBy}</span></div> : ''}
                        {notesUpdatedDate ?
                            <div className="button-wrap">Last updated at : <span>{notesUpdatedDate ? convertUTCToLocalDateTime(notesUpdatedDate) : 'N/A'}</span></div> : ''}
                    </div>
                    <div className="button-wrap">
                        <button data-testid="edit-toggle" id="edit-toggle" disabled={isEditDisabled} className={isEditMode ? 'btn btn-secondary' : 'btn btn-primary'} onClick={this.toggleEditMode}>{isEditMode ? 'Cancel' : 'Edit'}</button>
                        {isEditMode && <button data-testid="save-btn" id="save-btn" className="btn btn-primary ms-2" onClick={this.submit}>Save</button>}
                    </div>
                </div>
            </div>
        )
    }
}
