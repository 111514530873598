import React, { Component } from 'react'
import { convertUTCToLocalDateTime } from '../../helpers/GlobalFunctions';
import CustomModal from '../../shared/CustomModal';
import SideMenu from '../SideMenu/SideMenu'
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';
import { Link } from 'react-router-dom';
export default class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            singleDismissModalShow: false,
            massDismissModalShow: false,
            selectedNotification: null
        };
    }

    /**
    * @description Function to return custom class for borders in notification blocks 
    * @param notifCategory Notification category depending on which left border changes
    * @returns Corresponding class name as a string
    * @memberof Notifications
    */
    getClassName = (notifCategory) => {
        switch (notifCategory) {
            case "Data Sharing Request":
                return 'border-blue';

            case "License Expired":
                return 'border-red';

            case "Password Notifications":
                return 'border-green';

            default:
                break;
        }
    }

    /**
    * @description Function to decide if there needs to be a redirection
    * @param notifCategory Notification category depending on which redirection is decided
    * @memberof Notifications
    */
    isRedirect = (notifCategory) => {
        return notifCategory === "Data Sharing Request";
    }

    /**
    * @description Function to show a modal to confirm dismissal of one notification
    * @param notif Notification selected for dismissal
    * @memberof Notifications
    */
    dismissSingleConfirm = (notif) => {
        this.setState({
            selectedNotification: notif,
            singleDismissModalShow: true
        });
    }

    /**
    * @description Function to handle confirmation of dismissal of one notification
    * @memberof Notifications
    */
    singleDismiss = () => {
        this.props.onDismiss(this.state.selectedNotification);
        this.setState({
            selectedNotification: null,
            singleDismissModalShow: false
        });
    }

    /**
    * @description Function to show a modal to confirm dismissal of all notifications
    * @memberof Notifications
    */
    dismissMassConfirm = () => {
        this.setState({ massDismissModalShow: true });
    }

    /**
    * @description Function to handle confirmation of mass dismiss of notifications
    * @memberof Notifications
    */
    massDismiss = () => {
        this.props.onMassDismiss();
        this.setState({
            massDismissModalShow: false
        });
    }

    /**
    * @description Function to handle cancel or closing of a modal
    * @memberof Notifications
    */
    closeModal = () => {
        this.setState({
            selectedNotification: null,
            singleDismissModalShow: false,
            massDismissModalShow: false
        })
    }

    /**
    * @description Function to create notification template
    * @param notif Notification object
    * @memberof Notifications
    */
    getNotificationTemplate = (notif, index) => {
        return (
            <div className={`notification-block ${this.getClassName(notif.notificationcategory)}`}>
                <div className="notification">
                    <h5 className="notification-title">{notif.notificationcategory}</h5>
                    <p className="notification-sub-title p-0 m-0">{convertUTCToLocalDateTime(notif.createddatetime)}</p>
                    <p className="notification-body pt-4">{notif.notificationtext}</p>
                </div>
            </div>
        )
    }

    render() {
        const { notifications, notifDismissed, dismissMsg, notificationCount, loggerObj, notificatioError, dismissNotificationError } = this.props;
        const { singleDismissModalShow, massDismissModalShow } = this.state;
        return (
            <div className="bgGray">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-1">
                            <SideMenu notificationCount={notificationCount} loggerObj={loggerObj} />
                        </div>
                        <div className="col-md-11">
                            <div className="setting-header notifications-page row mt-4" data-testid="notifications">
                                <div className="col-md-12 p-4">
                                    <div className="row">
                                        <div className="col-sm-6 dashboard-block-title">Notifications</div>
                                        <div className="col-md-4 text-end">
                                            {notifications && notifications.length ?
                                                <button type="button" data-testid="dismiss-all" id="dismiss-all" className="btn btn-outline-danger me-2" onClick={() => this.dismissMassConfirm()}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 1 16 16">
                                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                                    </svg>&nbsp;
                                                    Dismiss All
                                                </button>
                                                : dismissNotificationError ? <ErrorMsgBlockComponent errorObject={dismissNotificationError} /> : ''}
                                        </div>
                                    </div>

                                    {notifications && notifications.length ?
                                        <div className="row">
                                            <div className="col-md-10 p-4">
                                                {notifDismissed ? <div className="row">
                                                    <div className="col-md-12">
                                                        <p className="text-success">{dismissMsg}</p>
                                                    </div>
                                                </div> : dismissNotificationError ? <ErrorMsgBlockComponent errorObject={dismissNotificationError} /> : ''}
                                                <div className="notifications-wrap">
                                                    {notifications.map((notif, index) => {
                                                        return (
                                                            <div key={`notif-${index + 1}`} className="row">
                                                                <div className="col-sm-11">
                                                                    {this.isRedirect(notif.notificationcategory) ?
                                                                        <Link
                                                                            className="data-share-notif-block"
                                                                            id={`notif-${index + 1}-redirect`}
                                                                            to={{
                                                                                pathname: `/settings`,
                                                                                state: { activeMainTab: 'dataSharing' }
                                                                            }}>
                                                                            {this.getNotificationTemplate(notif)}
                                                                        </Link> : this.getNotificationTemplate(notif)}
                                                                </div>
                                                                <div className="col-sm-1 p-0">
                                                                    <div className="action-area">
                                                                        <button type="button" disabled={notif.notificationcategory === "License Expired"} id={`notif-${index + 1}-dismiss`}  data-testid={`notif-${index + 1}-dismiss`}
                                                                            className="btn btn-outline-danger" onClick={() => this.dismissSingleConfirm(notif)} title="Dismiss">
                                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                            </svg> */}
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 2 16 16">
                                                                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        : notificatioError ? <ErrorMsgBlockComponent errorObject={notificatioError} /> : notifications && !notifications.length && <ErrorMsgBlockComponent noData={true} />}

                                    {/* Modal to confirm dismissal of one notification at a time */}
                                    <CustomModal
                                        id="single-dismiss"
                                        data-testid="single-dismiss"
                                        show={singleDismissModalShow}
                                        isConfirm={true}
                                        title="Dismiss Notification"
                                        body="Are you sure you want to dismiss this notification?"
                                        closeAction={this.closeModal}
                                        rejectAction={this.closeModal}
                                        confirmAction={this.singleDismiss}
                                    />

                                    {/* Modal to confirm dismissal of all notifications at once */}
                                    <CustomModal
                                        id="mass-dismiss"
                                        data-testid="mass-dismiss"
                                        show={massDismissModalShow}
                                        isConfirm={true}
                                        title="Dismiss All Notifications"
                                        body="Are you sure you want to dismiss all notifications?"
                                        closeAction={this.closeModal}
                                        rejectAction={this.closeModal}
                                        confirmAction={this.massDismiss}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
