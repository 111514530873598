import React, { Component } from 'react';
import { Range, getTrackBackground, useThumbOverlap } from 'react-range';

const ThumbLabel = ({
	rangeRef,
	values,
	index,
	openRange,
	openMinLabel,
	openMaxLabel,
	min,
	max
}) => {
	const [labelValue, style] = useThumbOverlap(rangeRef, values, index, 0, ' to ', (value) => {
			if (openRange) {
				if (parseInt(value) === parseInt(min)) {
					return openMinLabel;
				}

				if (parseInt(value) === parseInt(max)) {
					return openMaxLabel;
				}
			}
			return value;
		});
	return (
		<div data-label={index} className="range-thumb-bubble" style={style}>
			{labelValue}
		</div>
	);
};

export default class RangeInput extends Component {
	constructor(props) {
		super(props);
		this.rangeRef = React.createRef();
		this.state = {
			values: [parseInt(props.minVal), parseInt(props.maxVal)],
			reset: true
		}
	}

	componentDidUpdate(prevProps) {
		if (!this.props.reset && (prevProps.min !== this.props.min || prevProps.max !== this.props.max || 
			(this.props.id === 'femur-size' && this.props.implantValue !== prevProps.implantValue))) {
			this.setState({
				values: [parseInt(this.props.minVal), parseInt(this.props.maxVal)]
			});
		}

		if (this.props.reset && !prevProps.reset) {
			this.setState({
				values: [parseInt(this.props.minVal), parseInt(this.props.maxVal)],
				reset: false
			});
		}
	}

	/**
	 * @description Function to handle change of values
	 * @param values Current values
	 * @memberof RangeInput
	 */
	/* istanbul ignore next  */
	handleOnChange = (values) => {
		this.setState({ values, reset: false });
	}

	/**
	 * @description Function to handle change of values after movement of sliders is complete
	 * @param values Final values
	 * @memberof RangeInput
	 */
	/* istanbul ignore next  */
	handleFinalChange = (values) => {
		const minVal = values[0] <= values[1] ? values[0] : values[1];
		const maxVal = values[1] >= values[0] ? values[1] : values[0];
		this.props.handleChange([minVal, maxVal])
	}

	render() {
		const STEP = parseInt(this.props.step);
		const MIN = parseInt(this.props.min);
		const MAX = parseInt(this.props.max);
		const { openRange, openMinLabel, openMaxLabel, showExtremeLabels, minExtremeLabel, maxExtremeLabel } = this.props;
		const dataTestId = this.props['data-testid'];

		/* istanbul ignore next  */
		return (
			<div className="range-wrapper" data-testid="range-input">
				<Range
					className="range-input"
					data-testid={dataTestId}
					values={this.state.values ? this.state.values : []}
					step={STEP}
					min={MIN}
					max={MAX}
					allowOverlap={true}
					ref={this.rangeRef}
					onChange={this.handleOnChange}
					onFinalChange={this.handleFinalChange}
					renderTrack={({ props, children }) => (
						<div className="range-track-wrap" style={props.style} data-testid="range-track">
							{showExtremeLabels && <span className="range-extreme-label-min">{minExtremeLabel}</span>}
							<div className="range-track"
								ref={props.ref}
								style={{
									background: getTrackBackground({
										values: this.state.values,
										colors: ['#ccc', '#73BC51', '#ccc'],
										min: MIN,
										max: MAX
									})
								}}
							>
								{children}
							</div>
							{showExtremeLabels && <span className="range-extreme-label-max">{maxExtremeLabel}</span>}
						</div>
					)}
					renderThumb={({ index, props, isDragged }) => {
						let updatedStyles = { ...props.style, zIndex: 0 };
						switch (index) {
							case 0:
								if (this.state.values && this.state.values.length && this.state.values[1] === MAX) {
									updatedStyles.zIndex = 1;
								}
								break;

							case 1:
								if (this.state.values && this.state.values.length && this.state.values[0] === MIN) {
									updatedStyles.zIndex = 1;
								}
								break;

							default:
								updatedStyles.zIndex = props.style.zIndex;
								break;
						}

						return (
							<div className={index === 0 ? "range-thumb range-min" : (index === 1 ? "range-thumb range-max" : "range-thumb")} {...props} style={updatedStyles} data-testid={`range-thumb-${index}`}>
								<ThumbLabel
									rangeRef={this.rangeRef.current}
									values={this.state.values}
									index={index}
									openRange={openRange}
									openMinLabel={openMinLabel}
									openMaxLabel={openMaxLabel}
									min={MIN}
									max={MAX}
								/>
							</div>
						)
					}}
				/>
			</div>
		);
	}
}
