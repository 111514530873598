import React, { Component } from 'react';
import CustomModal from '../../shared/CustomModal';
import CommentModal from './CommentModal';
import { updateCommentForAssociationService } from '../../services/java/java-services';
import { customerMessages, loggerEventMessage, loggerEventName, loggerEventOutcome, loggerEventTypes } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
import { eventSource } from '../../helpers/constants';

export default class UpdateAssociation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCommentModal: false,
            selected: false,
            params: {
                Comment: '',
                requestedBy: '',
                event: '',
            },
            formErrors: {
                Comment: '',
                requestedBy: '',
            },
            updateParams: {
                value: '',
                id: '',
                type: '',
                selectedOptionText: '',
                salesrepName: '',
            },
            errorMessage: '',
            visionaierId: '',
            saveDisable: true,
        };
    }
    /**
     * @description function to maintain comment logs
     * @param id
     * @memberof UpdateAssociation
     **/
    /* istanbul ignore next  */
    updateCommentLogger = (id) => {
        const loggerObj = {
            EventSource: eventSource.kpopPlans.value,
            EventOutcome: loggerEventOutcome.success,
            EventType: loggerEventTypes.update,
            EventName: loggerEventName.updateCommentinCustomerSupport,

            Content: {
                Data: loggerEventMessage.commentUpdated,
                CaseId: id.toString(),
            },
        };
        logger(loggerObj);
    };


    /**
 * @description Get error message for comment validation
 * @param userComment Comment entered by the user
 * @returns Error message if validation fails, otherwise an empty string
 * @memberof UpdateAssociation
 */
    getCommentError = (userComment) => {
        if (!userComment) {
            return customerMessages.comment;
        }
        if (userComment.length > 250) {
            return customerMessages.commentMaxLength;
        }
        return '';
    };

    /**
     * @description Get error message for requestedBy validation
     * @param reqByUser Requested by user value
     * @returns Error message if validation fails, otherwise an empty string
     */
    getRequestedByError = (reqByUser) => {
        if (!reqByUser || reqByUser === 'Select User') {
            return customerMessages.requestedBy;
        }
        return '';
    };

    /**
     * @description Handle service error and update the state
     * @param err Error object or message
     * @memberof UpdateAssociation
     */
    handleServiceError = (err) => {
        this.setState({
            errorMessage: typeof err === 'string' ? err : JSON.stringify(err),
        });
    };

    /**
     * @description Handle service success and update the state
     * @param id ID of the comment
     * @param updateParams Parameters for updating the comment
     * @memberof UpdateAssociation
     */
    handleServiceSuccess = (id, updateParams) => {
        this.setState({ showCommentModal: false });
        const params = {...updateParams, requestedBy: this.state.params.requestedBy}
        this.props.updateReviewDataInDB(params);
        this.updateCommentLogger(id); // need to update this api if its differnt then hospital association api
    };

    /**
     * @description function to handle the update
     * @param id, params, updateParams
     * @memberof UpdateAssociation
     **/
    updateComment = (id, params, updateParams) => {
        const { Comment, requestedBy } = this.state.params;

        const msgs = {
            Comment: this.getCommentError(Comment),
            requestedBy: this.getRequestedByError(requestedBy),
        };

        /* istanbul ignore next  */
        if (msgs.Comment || msgs.requestedBy) {
            this.setState({ formErrors: msgs });
            return;
        }
        // needs to update the following function api if its differnt then hospital association

        /* istanbul ignore next  */
        updateCommentForAssociationService(id, params, (err, result) => {
            if (err) {
                this.handleServiceError(err);
            } else {
                if (result.status === 200) {
                    this.handleServiceSuccess(id, updateParams);
                }
            }
        });
    };

    /**
     * @description Function to disable selected option also trigger update API
     * @param  event Event: to get the value of option changed
     * @param  row object: all row data for which user changing hospital association
     * @param  type String: key for updating the data by update API identify it is for the status / hospital association
     * @memberof UpdateAssociation
     **/
    handleSelectChange = (event, row, type) => {
        const { value, selectedIndex, options: selectedOption } = event.target;
        const { VisionaierId, salesrepName } = row;
        const selectedOptionText = selectedOption[selectedIndex].text;
        let customerId = row.hospitalAssociation.find((x) => x.hospitalName === value)?.hospitalId;
        this.setState({
            saveDisable: true,
            selected: true,
            showCommentModal: true,
            visionaierId: `${VisionaierId}`,
            params: { event: `Hospital Association updated to ${selectedOptionText}` },
        });

        if(value === 'unselect'){
            customerId = value;
        }
        

        // disable the selected option to prevent deadlock
        const options = event.target.options;
        for (let option of options) {
            if (option.value === value) {
                option.disabled = true;
            } else {
                option.disabled = false;
            }
        }

        // before below API gets called need to call comment history api to ensure
        // who is updating hospital association and store details of that user in DB
        this.setState({
            updateParams: {
                value: customerId?.toString(),
                id: VisionaierId,
                type: type,
                selectedOptionText: selectedOptionText,
                salesrepName,
            },
        });
    };


      /**
    * @description Function to Get the Status Label 
    * @param Procedure List, status
    * @memberof UpdateAssociation
    */

    getStatusLabel = (list, status) => {
        if (!status) return 'N/A';

        if (status === '9') {
            return 'Operation Complete'
        }

        return list.find(x => x.planStatus === Number(status))?.status
    }

    /**
     * @description Function to disable selected option also trigger update API
     * @param  event Event: to get the value of option changed
     * @param  row object: all row data for which user changing Sales Rep
     * @param  type String: key for updating the data by update API identify it is for the status / sales rep
     * @memberof UpdateAssociation
     **/
    handleSalesRepSelectChange = (event, row, type) => {
        const { selectedIndex, options: selectedOption } = event.target;
        const { VisionaierId } = row;
        const selectedOptionText = selectedOption[selectedIndex].text;
        const  value = this.props.salesRepList?.find(x=>x.salesRepName === selectedOptionText)?.username || selectedOptionText;
        // ${selectedOptionText} Selected Sales Rep User
        this.setState({
            saveDisable: true,
            selected: true,
            showCommentModal: true,
            visionaierId: `${VisionaierId}`,
            params: { event: "Sales Rep Changed" },
            updateParams: {
                value,
                id: VisionaierId,
                type: type,
                selectedOptionText: selectedOptionText,
                salesrepName: value,
                status: this.getStatusLabel(row.procedureStatus, row.currentStatus),
                oldSalesrepUsername:   row.salesrepName
            },
        })
    };

    /**
     * @description function to close the modal
     * @memberof `UpdateAssociation`
     **/
    closeRejectModalHandler() {
        /* istanbul ignore next  */
        this.state.showCommentModal ? this.setState({ showCommentModal: false, formErrors: { requestedBy: '', Comment: '' } }) : this.setState({ showCommentModal: true });
    }

    /**
     * @description function to handle the dropdpown change
     * @memberof `UpdateAssociation`
     **/
    onOptionChangeHandler = (event) => {
        const params = this.state.params;
        params['requestedBy'] = event.target.value;
        this.setState({ params, formErrors: { ...this.state.formErrors, requestedBy: '' }, saveDisable: false });
    };

    /**
     * @description function to handle the comment input
     * @memberof `UpdateAssociation`
     **/
    commentHandler = (event) => {
        const params = this.state.params;
        params['Comment'] = event.target.value;
        this.setState({ params, formErrors: { ...this.state.formErrors, Comment: '' }, saveDisable: false });
    };

    render() {
        const { row, type } = this.props;
        const { params, visionaierId, updateParams } = this.state;
        const defaultHospital = row?.hospitalName;

        // Check if the default hospital exists in the list
        const exists = row?.hospitalAssociation.some((item) => item.hospitalName === defaultHospital);

        // If it doesn't exist, add it to the list and mark it as deleted
        if (!exists && defaultHospital) {
            row.hospitalAssociation.push({ hospitalName: defaultHospital, hospitalId: '', deleted: true });
        }

        return (
            <>
                <div data-testid="update-association">
                    {type === 'hospitalAssociation'  &&  row?.currentStatus !=='9' && (
                        <select
                            data-testid='select-hospital'
                            className='form-select form-select-sm'
                            defaultValue={row?.hospitalName?.toString() ? row?.hospitalName?.toString() : 'unselect'}
                            onChange={(e) => this.handleSelectChange(e, row, 'hospitalId')}
                        >
                            {!this.state?.selected && <option value='unselect' >Select Any Customer</option>}
                            {row?.hospitalAssociation?.map((item) => {
                                return (
                                    <option key={item?.hospitalId} value={item?.hospitalName} disabled={item?.deleted}>
                                        {item?.hospitalName}
                                    </option>
                                );
                            })}
                        </select>
                    )}
                    {type === 'hospitalAssociation' &&  row?.currentStatus ==='9' && (
                       <p>{row.hospitalName}</p>
                     )}
                </div>

                <>
                {type === 'salesRepFullName'  &&  row?.currentStatus !=='9' && (
                        <select
                            data-testid='select-sales-rep'
                            className='form-select form-select-sm'
                            defaultValue={row?.salesRepFullName?.toString()}
                            onChange={(e) => this.handleSalesRepSelectChange(e, row, 'salesRep')}
                        >
                            {this.props.salesRepList?.map((item) => {
                                return (
                                    <option key={item?.username + row.VisionaierId} value={item?.salesRepName} >
                                        {item?.salesRepName}
                                    </option>
                                );
                            })}
                        </select>
                    )}
                    {type === 'salesRepFullName' &&  row?.currentStatus ==='9' && (
                       <p>{row.salesRepFullName}</p>
                     )}
                </>

                <div className='add-History-modal'>
                    {/* Modal to add comment who is changing hospital association in customer support*/}
                    <CustomModal
                        id='addCommentCustomerSupportModal'
                        data-testid="addCommentCustomerSupportModal"
                        dialogClassName='add-comment-dialog'
                        show={this.state.showCommentModal}
                        confirmText='Save'
                        rejectText='Cancel'
                        isComponent={true}
                        isConfirm={true}
                        title='Add User Details'
                        closeAction={() => this.closeRejectModalHandler()}
                        rejectAction={() => this.closeRejectModalHandler()}
                        confirmAction={() => this.updateComment(visionaierId, params, updateParams)}
                        hideClose={false}
                        saveDisable={this.state.saveDisable}
                    >
                        <CommentModal onOptionChangeHandler={this.onOptionChangeHandler} commentHandler={this.commentHandler} errors={this.state.formErrors} />
                    </CustomModal>
                </div>
            </>
        );
    }
}
