import React, { Component } from 'react';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';
import GapsDetails from './GapsDetails';

export default class GapsComponent extends Component {
    render() {
        const { gapError, isTensioner, showGapGraph, isManualDisabled, noGraphSelected, manualBinned, tensionerBinned, handleGapGraphChange } = this.props;

        return (
            <div className="case-details-gaps">
                <div className="d-flex align-items-center justify-content-between" data-testid="gaps-component">
                    <h2 className="case-details-gaps-title">Post-Op Gap Balance</h2>

                    <div className="gap-selection-wrap mx-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="form-check me-3">
                                <input type="checkbox" className="form-check-input" id="gap-tensioner" value="tensioner" name="gap-graph-seletion"
                                    disabled={!isTensioner || isManualDisabled} checked={showGapGraph.indexOf('tensioner') > -1} onChange={handleGapGraphChange}
                                />
                                <label className="form-check-label" htmlFor="gap-tensioner">Tensioner</label>
                            </div>

                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="gap-manual" value="manual" name="gap-graph-seletion"
                                    disabled={!isTensioner || isManualDisabled} checked={showGapGraph.indexOf('manual') > -1} onChange={handleGapGraphChange} />
                                <label className="form-check-label" htmlFor="gap-manual">Manual</label>
                            </div>
                        </div>
                    </div>
                </div>
                {!showGapGraph.length ? <ErrorMsgBlockComponent errorObject={noGraphSelected} /> : ''}

                {showGapGraph.indexOf('tensioner') > -1 ?
                    tensionerBinned ? <GapsDetails gapData={tensionerBinned} title="Tensioner" /> :
                        gapError ? <ErrorMsgBlockComponent errorObject={gapError} /> : <ErrorMsgBlockComponent noData={true} />
                    : ''}

                {showGapGraph.indexOf('manual') > -1 ?
                    manualBinned ? <GapsDetails gapData={manualBinned} title="Manual" /> :
                        gapError ? <ErrorMsgBlockComponent errorObject={gapError} /> : <ErrorMsgBlockComponent noData={true} />
                    : ''}
            </div>
        )
    }
}
