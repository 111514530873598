import React, { Component } from "react";
import { contactForm } from "../../helpers/messages";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default class ContactForm extends Component {
	constructor() {
		super();
		this.editor = null;
	}
	/* istanbul ignore next */
	componentDidUpdate() {
		if (this.props.isSubmitted && this.editor) {
			this.editor.setData('');
			this.props.toggleIsSubmitted(false);
		}
	}

	/**
	 * @description Function to set an instance for the editor
	 * @param event On ready event from the editor
	 */
	onReady = (event) => {
		this.editor = event.editor;
        // Automatically adjust the default height of the editor 
		event.editing.view.change((writer) => {
			writer.setStyle(
				"min-height",
				"300px",
				event.editing.view.document.getRoot()
			);
		});
		event.editing.view.document
		.on("clipboardInput", (evt,data) => {
		  const pastedHtml = data.dataTransfer.getData('text/html');
		  const containsImg = /<img\s[^>]*src="[^"]*"[^>]*>/g.test(pastedHtml);
		  if (containsImg) {
			  evt.stop();
			  return;
			}
		})
		
		this.props.handleInputChange(event);
	}

	render() {
		const { config, isEditMode, editorData, handleInputChange, subject, handleSubmit, maxWordCount, handleChange, isError, errorMessage, maxWordCountError, wordLeft, errors } = this.props;
		
		return (
			<div className="row" data-testid="contact-form">
				<div className="col-md-12">
					{isError === true ? <div className="text-success">{contactForm.feedbackSend} </div> : ''}
					{isError === false ? <div className="text-danger"> {errorMessage} </div> : ''}
				</div>
				<div className="col-md-12">
					<form onSubmit={handleSubmit}>
						<label className="mt-3">Subject<span className="red">*</span></label>
						<input
							type="text"
							data-testid="subject"
							id="subject"
							className="form-control"
							name="subject"
							placeholder="Subject"
							value={subject}
							onChange={(e) => handleChange(e.target.name, e.target.value, "subject") }
						/>
						<span className="text-danger">{errors.subject}</span>
						<div className="editor-wrapper contact-form mt-4">
							<label>Feedback<span className="red">*</span></label>
							<CKEditor
								id="ck_id"
								disabled={isEditMode}
								editor={ClassicEditor}
								config={config}
								data={editorData}
								onReady={this.onReady}
								onChange={(event, editor) => {
									handleInputChange(editor);
								}}
							/>
						</div>
						
						<div className="mt-2 editor-footer d-flex justify-content-between align-items-center">
							<div className="error-block">
								{maxWordCountError && <div className="error" data-testid="max-word-count-error">{contactForm.wordsExceeded(maxWordCount)}</div>}
								{errors.editorData && <div className="error">{errors.editorData}</div>}
							</div>
							<div className="word-count">
								<strong>Words Remaining: </strong>
								{wordLeft}
							</div>
						</div>
						<div className="text-end mt-3">
							<button data-testid="send-btn" id="send-btn" type="button" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
