import React, { Component } from 'react';
import SideMenu from '../SideMenu/SideMenu';
import Header from '../Header/Header';
import { connect } from 'react-redux';
import SurgeonListTable from './SurgeonListTable';
class SurgeonListComponent extends Component {
	render() {
		const { loggerObj, fromDashboard, handleSearchSubmit, handleReset, refreshSurgeonListTable, searchString, searchSubmit } = this.props;

		return (
			<div>
				<Header />
				<div className="bgGray">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-1">
								<SideMenu loggerObj={loggerObj} />
							</div>
							<div className="col-md-11">
								<div className="surgeon-list data-list-tabs table-wrap px-3" data-testid="surgeon-list">
									<SurgeonListTable
										fromDashboard={fromDashboard}
										handleSearchSubmit={handleSearchSubmit}
										handleReset={handleReset}
										refreshSurgeonListTable={refreshSurgeonListTable}
										searchString={searchString}
										searchSubmit={searchSubmit}
										 />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => (
	{
		state
	}
)
const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(SurgeonListComponent);
