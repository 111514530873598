import React, { Component } from 'react';
import { getCustomerLastSyncData } from '../../services/java/java-services';
import { convertUTCToLocalDateTime } from '../../helpers/GlobalFunctions';

export default class HistorySyncModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            lastSync: '',
            plans: 0,
            status: '',
            syncedBy: '',
            loading: true,
            planSuccessCount: 0,
        };
    }

    /**
     * @description show Last sync details in customer support user
     * @memberof HistorySyncModal
     */

    componentDidMount() {
        this.fetchLastSyncData();
    }

    fetchLastSyncData() {
        /* istanbul ignore next  */
        getCustomerLastSyncData((err, response) => {
            if (err) {
                this.setState({ errorMessage: err.message, loading: false });
            } else {
                this.setState({ errorMessage: null, loading: false });
                this.setState(response.data);
            }
        });
    }

    render() {
        return (
            <div className='history-sync-modal' data-testid="history-sync-modal">
                <div className='container'>
                    {
                        /* istanbul ignore next  */
                        this.state.errorMessage && (
                            <div className='row'>
                                <div className='col-sm-12' style={{ textAlign: 'center', color: 'red' }}>
                                    {this.state.errorMessage}
                                </div>
                            </div>
                        )
                    }

                    {this.state.loading && (
                        <div className='row'>
                            <div className='col-sm-12' style={{ textAlign: 'center' }}>
                                Loading...
                            </div>
                        </div>
                    )}

                    {!this.state.loading && !this.state.errorMessage && (
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <table className='table table-borderless raw-table'>
                                        <tbody>
                                            <tr>
                                                <td className='heading-static'>
                                                    <strong>Status:</strong>
                                                </td>
                                                <td className='full-text sync-status'>{this.state.status}</td>
                                            </tr>

                                            <tr>
                                                <td className='heading-static'>
                                                    <strong>Total # of Plans:</strong>
                                                </td>
                                                <td className='full-text sync-total-plans'>{this.state.plans}</td>
                                            </tr>
                                            <tr>
                                                <td className='heading-static'>
                                                    <strong>Synced By:</strong>
                                                </td>
                                                <td className='full-text sync-by'>{this.state.syncedBy}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <table className='table table-borderless raw-table'>
                                        <tbody>
                                            <tr>
                                                <td className='heading-static'>
                                                    <strong>Last Sync:</strong>
                                                </td>
                                                <td className='full-text last-sync'>{this.state.lastSync ? convertUTCToLocalDateTime(this.state.lastSync) : ''}</td>
                                            </tr>

                                            <tr>
                                                <td className='heading-static'>
                                                    <strong>Plans Synced:</strong>
                                                </td>
                                                <td className='full-text synced-plans'>{this.state.planSuccessCount}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
