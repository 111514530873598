import React, { Component } from "react";
import SideMenu from '../SideMenu/SideMenu';
import { DataTable } from '../DataTable/DataTable';
import DeviceMessage from "./DeviceMessage"
export default class DeviceList extends Component {


    render() {
        const { fromDashboard, searchString, searchSubmit, refreshDeviceList, handleSearchSubmit, handleReset, deviceAdded } = this.props;
        /* istanbul ignore next  */
        const columns = [
            {
                Header: 'Serial Number',
                accessor: 'serialno',
                Cell: ({ row }) => (<span>{row.original.serialno ? row.original.serialno : 'N/A'}</span>),
                Filter: ''
            },
            {
                Header: 'Customer Name',
                accessor: 'customername',
                Cell: ({ row }) => (<span>{row.original.customername ? row.original.customername : 'N/A'}</span>),
                Filter: ''
            },
            {
                Header: 'Status',
                deviceAction: true
            }


        ];
        return (
            <div className="bgGray">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-1">
                            <SideMenu />
                        </div>
                        <div className="col-md-11">
                            <div className="device-list data-list-tabs table-wrap px-3" data-testid="deviceList">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1 className="file-list-heading">Device List</h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <DeviceMessage
                                            handleSearchSubmit={handleSearchSubmit}
                                            handleReset={handleReset}
                                            deviceAdded={deviceAdded}
                                            fromDashboard={fromDashboard}
                                        />
                                    </div>
                                </div>
                                <div className="user-list-container">
                                    <DataTable
                                        listType="device-list"
                                        columns={columns}
                                        showPagination={true}
                                        searchString={searchString && searchSubmit ? searchString : ''}
                                        refreshDataList={searchSubmit || refreshDeviceList}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
