import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock'

class AddLicenseComponent extends Component {
    render() {
        const {durationObj,licenseTypeObj, handleInputChange, saveLicense, onEnterPress, formErrors,showLicenseErr, 
            cancelAction, isLoading} = this.props;

            function dynamicErrorHandle(key){                
                if(formErrors && formErrors[key]) {
                    return (
                        <div className="customer-error-msg">
                        <p>{formErrors[key]}</p>
                       </div>
                    )
                }
                return (<></>)
            }

            function fieldErrorValidate(key){
                if(formErrors && formErrors[key]) {
                  return true
                }
                return false
            }

        return (
            <>
            <div>
                    <div className="row">
                        <div className="col-sm-12" data-testid="add-license-form">
                            {showLicenseErr ? <ErrorMsgBlockComponent errorObject={showLicenseErr} className="pb-4 m-0"/> :''}    
                            <form onSubmit={onEnterPress} noValidate>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Start Date<span className="red">*</span></label>
                                <div className="col-sm-8">
                                <input className="form-control" type="date" data-testid="licenseStartDate" id="licenseStartDate"
                                    onChange={(e) => handleInputChange('licenseStartDate', e.target.value)}/>
                                    {dynamicErrorHandle('licenseStartDate')}
                                </div>
                            </div>                        
                            
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Duration<span className="red">*</span></label>
                                <div className="col-sm-8">
                                    <select
                                        className={`form-select enabled-box ${fieldErrorValidate('duration')? 'has-error' : ''}`}
                                        id="duration"
                                        data-testid="duration"
                                        onChange={(e) => handleInputChange('duration', e.target.value)}
                                        onKeyPress={ onEnterPress }
                                    >
                                        <option value=''>Select Duration</option>
                                        {durationObj ? durationObj.map((c, i) => (
                                            <option key={`duration-${i}`} value={c.key}>{c.value}</option>
                                        )) : ''}
                                        
                                    </select>
                                    {dynamicErrorHandle('duration')}
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">License Type<span className="red">*</span></label>
                                <div className="col-sm-8">
                                    <select
                                        className={`form-select enabled-box ${fieldErrorValidate('licenseType')? 'has-error' : ''}`}
                                        id="licenseType"
                                        data-testid="licenseType"
                                        onChange={(e) => handleInputChange('licenseType', e.target.value)}
                                        onKeyPress={ onEnterPress }
                                    >
                                        <option value=''>Select License Type</option>
                                        {licenseTypeObj ? licenseTypeObj.map((c, i) => (
                                            <option key={`licenseType-${i}`} value={c.key}>{c.value}</option>
                                        )) : ''}
                                        
                                    </select>
                                    {dynamicErrorHandle('licenseType')}
                                </div>
                            </div>
                      
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">PO Number</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        id="poNumber"
                                        data-testid="poNumber"
                                        autoComplete="off"
                                        maxLength="12"
                                        className={`form-control ${fieldErrorValidate('poNumber') ? 'has-error' : ''}`}
                                        onChange={(e) => handleInputChange('poNumber', e.target.value)}
                                        onKeyPress={ onEnterPress }
                                    />
                                    {dynamicErrorHandle('poNumber')}
                                </div>
                            </div>
                            </form>
                            <div className="add-license-actions-btn col-sm-12">
                                { isLoading ? <button className="btn btn-primary disabled">
                                        <span className="spinner-border spinner-border-sm"></span>
                                        &nbsp;Saving data...
                                      </button> : <button className="btn btn-primary proms-btn-color" type="button" id="saveLicense" onClick={saveLicense}>Save</button> }
                                <button className="btn btn-secondary add-license-btn-cancel" type="button"  id="cancelsaveLicense" onClick={cancelAction}>Cancel</button>
                            </div>
                            
                        </div>
                    </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = (state) => (
    {
        state
    }
)
const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(AddLicenseComponent);