import React, { Component } from 'react';
import SideMenu from '../SideMenu/SideMenu';
import Header from '../Header/Header';
import { actions , deviceMessages} from '../../helpers/messages';
import { Link } from 'react-router-dom';
import { SurgeonList } from '../Dashboard/RAR/SurgeonList';
import CustomModal from '../../shared/CustomModal';
import AddSurgeon from '../Dashboard/RAR/AddSurgeon';
import AddLicense from '../../containers/CustomerDetails/AddLicense';
import CustomerDevice from '../../components/CustomerDevice/CustomerDevice';
import { formatDate, getLicenseTypeName } from '../../helpers/GlobalFunctions';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';
import AssociatedCareteam from './AssociatedCareteam';
import { checkLocalStorage, getLocalStorage, setLocalStorage } from '../../services/storage/storage-service';



export default class CustomerDetailsComponent extends Component {
    constructor(props) {
        super(props);
        this.myFormRef = React.createRef();
        let selections = {
            showSurgeonsBlock: true,
            showCareTeamBlock: true,
            showDeviceBlock: true,
            showLicenseBlock: true,
            showAssociatedSalesRepBlock: true,
        };
        
        /* istanbul ignore next  */
        if (checkLocalStorage('customerProfileSelection')) {
            selections = JSON.parse(getLocalStorage('customerProfileSelection'));
        }

        this.state = {
            showSurgeonsBlock: selections.showSurgeonsBlock,
            showCareTeamBlock: selections.showCareTeamBlock,
            showDeviceBlock: selections.showDeviceBlock,
            showLicenseBlock: selections.showLicenseBlock,
            showAssociatedSalesRepBlock: selections.showAssociatedSalesRepBlock,
			refreshDeviceList: '',
            showDeviceAddSuccessMsg: ''
        };
    }

    /**
     * @description Function to disable edit mode while restting the form
     * @memberof CustomerDetailsComponent
     */
    /* istanbul ignore next  */
    cancelEdit = () => {
        this.myFormRef.reset();
        this.props.toggleEditMode();
    }

    getShowTextTemplate = (panelName, text) => {
        return (
            <div className="showText text-left mt-2">
                <p className="m-0 text-primary d-inline-block fs-8-rem cursor-pointer" id={`${panelName}-panel`} onClick={(e) => this.togglePanels(panelName, e)}>Show {text}</p>
            </div>
        )
    }

    togglePanels = (panelName, event) => {
        event.preventDefault();
        switch (panelName) {
            case 'surgeon':
                this.setState(prevState => {
                    return {
                        showSurgeonsBlock: !prevState.showSurgeonsBlock
                    }
                })
                break;
            case 'careTeam':
                this.setState(prevState => {
                    return {
                        showCareTeamBlock: !prevState.showCareTeamBlock
                    }
                })
                break; 
            case 'device':
                this.setState(prevState => {
                    return {
                        showDeviceBlock: !prevState.showDeviceBlock
                    }
                })
                break;
            case 'license':
                this.setState(prevState => {
                    return {
                        showLicenseBlock: !prevState.showLicenseBlock
                    }
                })
                break;
            case "associatedSalesRep":
                this.setState((prevState) => {
                    return {
                        showAssociatedSalesRepBlock: !prevState.showAssociatedSalesRepBlock,
                    };
                });
                break;
            /* istanbul ignore next  */
            default:
                break;
        }
        /* istanbul ignore next  */
        setTimeout(() => {
            let localStorageItem = {
                showSurgeonsBlock: this.state.showSurgeonsBlock,
                showCareTeamBlock: this.state.showCareTeamBlock,
                showDeviceBlock: this.state.showDeviceBlock,
                showLicenseBlock: this.state.showLicenseBlock,
                showAssociatedSalesRepBlock: this.state.showAssociatedSalesRepBlock,
            }
            setLocalStorage('customerProfileSelection', JSON.stringify(localStorageItem));
        }, 0);
    }

	/* istanbul ignore next */
	deviceAdded = () => {
		this.setState({ refreshDeviceList: '' ,showDeviceAddSuccessMsg: deviceMessages.deviceSaved });
		setTimeout(() => {
			this.setState({ showDeviceAddSuccessMsg: '' });
		}, 5000);
	}

    render() {
        const { customer, customerError, customerSaveError, loading, editMode, editObj, toggleEditMode, countryObj, stateObj, dialCodeObj, country, custState, countrydialcode, handleInputChange, saveCustomer,
            onEnterPress, formErrors, handleSearchInputChange, onSearchEnterPress, findSurgeons, searchResults, searchError, refreshDataList, enableAddSurgeonModal, showAddSurgeonModal,
            showSuccessMsg, loggerObj, showAddLicenseModal, enableAddLicenseModal, licenseList, refreshSurgeon, refreshLicenseDataList, licenseListloading, licenseError,
            licenseSuccessMsg, disableSubmitBtn, enableCareTeamModal, showAddCareteamModal, findCareTeam, searchResultCareteam, careteamList, careTeamListloading, careTeamError, refreshCareteamList, 
            associatedSalesRepList, associateSalesRepError, associateSalesRepLoading, } = this.props;

        const { showSurgeonsBlock, showCareTeamBlock, showDeviceBlock, showLicenseBlock, showAssociatedSalesRepBlock } = this.state;
        const surgeons = customer && customer.surgeonDetails && customer.surgeonDetails.length ? customer.surgeonDetails : [];
        /* istanbul ignore next  */
        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                Filter: ''
            },
            {
                Header: 'Username',
                accessor: 'userName',
                Filter: ''
            },
            {
                Header: 'Email',
                accessor: 'email',
                Filter: ''
            },
            {
                Header: 'Surgeon ID',
                accessor: 'surgeonId',
                Filter: ''
            },
            {
                Header: ' ',
                AssociatedSurgeonAction: true
            }
        ];
        /* istanbul ignore next  */
        const licenseColumns = [
            {
                Header: 'Date of Creation',
                accessor: 'entryDate',
                Cell: ({ row }) => row.original.entryDate ? formatDate(row.original.entryDate) : '',
                Filter: ''
            },
            {
                Header: 'License Type',
                accessor: 'licenseType',
                Cell: ({ row }) => row.original.licenseType ? getLicenseTypeName(row.original.licenseType) : '',
                Filter: ''
            },
            {
                Header: 'PO Number',
                accessor: 'po',
                Filter: ''
            },
            {
                Header: 'Valid From',
                accessor: 'validFrom',
                Cell: ({ row }) => row.original.validFrom ? formatDate(row.original.validFrom) : '',
                Filter: ''
            },
            {
                Header: 'Valid To',
                accessor: 'valitTo',
                Cell: ({ row }) => row.original.valitTo ? formatDate(row.original.valitTo) : '',
                Filter: ''
            },
            {
                Header: 'License Key',
                accessor: '',
                Filter: ''
            },
            {
                Header: 'Status',
                accessor: 'isActive',
                Filter: ''
            },
            {
                Header: 'Active Users',
                accessor: 'activeUsers',
                Filter: ''
            }
        ];
        /* istanbul ignore next  */
        const careteamColumns = [
            {
                Header: 'Name',
                accessor: 'name',
                Filter: ''
            },
            {
                Header: 'Username',
                accessor: 'username',
                Filter: ''
            },
            {
                Header: 'Email',
                accessor: 'email',
                Filter: ''
            },
            {
                Header: ' ',
                AssociatedCareteamAction: true
            }
        ];
        const associatedSalesRepColumns = [
            {
              Header: "Name",
              accessor: "name",
              Filter: "",
            },
            {
              Header: "User Name",
              accessor: "username",
              Filter: "",
            },
            {
              Header: "Email",
              accessor: "email",
              Filter: "",
            }
          ];
        return (
            <>
                {loading ? <div className="loading-overlay"><span>{actions.load}</span></div> : ''}
                <div>
                    <Header />
                    <div className="bgGray">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-1">
                                    <SideMenu loggerObj={loggerObj} />
                                </div>
                                <div className="col-md-11">
                                    <div className="device-list data-list-tabs review-case-details-wrap" data-testid="customerDetails">
                                        <div className="case-details-top-block align-items-center justify-content-between">
                                            <h1 className="case-details-heading">Customer Profile</h1>
                                            <div className="case-details-back-btn text-end">
                                                <Link className="btn btn-primary case-action-btn" id="view-case" to={"/customerlist"}> Back to Customer List</Link>
                                            </div>
                                        </div>

                                        {loading ? '' : customer ?
                                            <div className="customer-fields mb-4">
                                                <div className="case-details-container">
                                                    <div className="card-body">
                                                        <h2 className="card-title pb-2">Customer Details</h2>
                                                        <form onSubmit={onEnterPress} noValidate ref={(el) => this.myFormRef = el}>
                                                            <div className="row">
                                                                {showSuccessMsg ?
                                                                    <div className="col-sm-12">
                                                                        <div className="customer-save-success-msg">
                                                                            <p>{showSuccessMsg}</p>
                                                                        </div>
                                                                    </div>
                                                                    : ''}
                                                                {customerSaveError ?
                                                                    <div className="col-sm-12">
                                                                        <div className="customer-save-error-msg">
                                                                            <ErrorMsgBlockComponent errorObject={customerSaveError} />
                                                                        </div>
                                                                    </div>
                                                                    : ''}
                                                                <div className="col-sm-4">
                                                                    <div className="form-group row">
                                                                        <label className="col-sm-4 col-form-label">Customer Name{editMode ? <span className="red">*</span> : ''}</label>
                                                                        <div className="col-sm-8">
                                                                            <input type="text" disabled value={customer.customername} className="form-control" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-sm-4 col-form-label">Customer ID{editMode ? <span className="red">*</span> : ''}</label>
                                                                        <div className="col-sm-8">
                                                                            <input type="text" disabled value={customer.customerid} className="form-control" />
                                                                        </div>
                                                                    </div>
                                                                    <fieldset className="customer-fields" disabled={!editMode}>
                                                                        <div className="form-group row">
                                                                            <label className="col-sm-4 col-form-label">Address Line 1{editMode ? <span className="red">*</span> : ''}</label>
                                                                            <div className="col-sm-8">
                                                                                <input
                                                                                    type="text"
                                                                                    id="addr1"
                                                                                    data-testid="addr1"
                                                                                    autoComplete="off"
                                                                                    className={`form-control ${formErrors && formErrors.addressline1 ? 'has-error' : ''}`}
                                                                                    value={editObj && editObj.addressline1 ? editObj.addressline1 : ''}
                                                                                    onChange={(e) => handleInputChange('addressline1', e.target.value)}
                                                                                />
                                                                                {formErrors && formErrors.addressline1 ?
                                                                                    <div className="customer-error-msg">
                                                                                        <p>{formErrors.addressline1}</p>
                                                                                    </div>
                                                                                    : ''}
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <label className="col-sm-4 col-form-label">Address Line 2</label>
                                                                            <div className="col-sm-8">
                                                                                <input
                                                                                    type="text"
                                                                                    id="addr2"
                                                                                    data-testid="addr2"
                                                                                    autoComplete="off"
                                                                                    className={`form-control ${formErrors && formErrors.addressline2 ? 'has-error' : ''}`}
                                                                                    value={editObj && editObj.addressline2 ? editObj.addressline2 : ''}
                                                                                    onChange={(e) => handleInputChange('addressline2', e.target.value)}
                                                                                />
                                                                                {formErrors && formErrors.addressline2 ?
                                                                                    <div className="customer-error-msg">
                                                                                        <p>{formErrors.addressline2}</p>
                                                                                    </div>
                                                                                    : ''}
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>

                                                                <div className="col-sm-4">
                                                                    <fieldset className={`customer-fields ${editMode ? 'editmode' : ''}`} disabled={!editMode}>
                                                                        <div className="form-group row">
                                                                            <label className="col-sm-4 col-form-label">Postal Code{editMode ? <span className="red">*</span> : ''}</label>
                                                                            <div className="col-sm-8">
                                                                                <input
                                                                                    type="text"
                                                                                    id="postalcode"
                                                                                    data-testid="postalcode"
                                                                                    autoComplete="off"
                                                                                    className={`form-control ${formErrors && formErrors.postalcode ? 'has-error' : ''}`}
                                                                                    value={editObj && editObj.postalcode ? editObj.postalcode : ''}
                                                                                    onChange={(e) => handleInputChange('postalcode', e.target.value)}
                                                                                />
                                                                                {formErrors && formErrors.postalcode ?
                                                                                    <div className="customer-error-msg">
                                                                                        <p>{formErrors.postalcode}</p>
                                                                                    </div>
                                                                                    : ''}
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <label className="col-sm-4 col-form-label">Country{editMode ? <span className="red">*</span> : ''}</label>
                                                                            <div className="col-sm-8">
                                                                                <select
                                                                                    className={`form-select enabled-box ${formErrors && formErrors.country ? 'has-error' : ''}`}
                                                                                    id="country"
                                                                                    data-testid="country"
                                                                                    value={country}
                                                                                    onChange={(e) => handleInputChange('country', e.target.value)}
                                                                                    onKeyPress={onEnterPress}
                                                                                >
                                                                                    <option value=''>Select</option>
                                                                                    {countryObj ? countryObj.map((c, i) => (
                                                                                        <option key={`country-${i}`} value={c.countryname}>{c.countryname}</option>
                                                                                    )) : ''}
                                                                                </select>
                                                                                {formErrors && formErrors.country ?
                                                                                    <div className="customer-error-msg">
                                                                                        <p>{formErrors.country}</p>
                                                                                    </div>
                                                                                    : ''}
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <label className="col-sm-4 col-form-label">State{editMode ? <span className="red">*</span> : ''}</label>
                                                                            <div className="col-sm-8">
                                                                                <select
                                                                                    className={`form-select enabled-box ${formErrors && formErrors.state ? 'has-error' : ''}`}
                                                                                    id="state"
                                                                                    data-testid="state"
                                                                                    value={custState}
                                                                                    onChange={(e) => handleInputChange('state', e.target.value)}
                                                                                    onKeyPress={onEnterPress}
                                                                                >
                                                                                    <option value=''>Select</option>
                                                                                    {stateObj ? stateObj.map((s, i) => (
                                                                                        <option key={`state-${i}`} value={s.stateName}>{s.stateName}</option>
                                                                                    )) : ''}
                                                                                </select>
                                                                                {formErrors && formErrors.state ?
                                                                                    <div className="customer-error-msg">
                                                                                        <p>{formErrors.state}</p>
                                                                                    </div>
                                                                                    : ''}
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <label className="col-sm-4 col-form-label">Country Dial Code{editMode ? <span className="red">*</span> : ''}</label>
                                                                            <div className="col-sm-8">
                                                                                <select
                                                                                    className={`form-select enabled-box ${formErrors && formErrors.countrydialcode ? 'has-error' : ''}`}
                                                                                    id="countrydialcode"
                                                                                    data-testid="countrydialcode"
                                                                                    value={countrydialcode}
                                                                                    onChange={(e) => handleInputChange('countrydialcode', e.target.value)}
                                                                                    onKeyPress={onEnterPress}
                                                                                >
                                                                                    <option value=''>Select</option>
                                                                                    {dialCodeObj ? dialCodeObj.map((d, i) => (
                                                                                        <option key={`dialcode-${i}`} value={d}>{d}</option>
                                                                                    )) : ''}
                                                                                </select>
                                                                                {formErrors && formErrors.countrydialcode ?
                                                                                    <div className="customer-error-msg">
                                                                                        <p>{formErrors.countrydialcode}</p>
                                                                                    </div>
                                                                                    : ''}
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>

                                                                <div className="col-sm-4">
                                                                    <fieldset className="customer-fields" disabled={!editMode}>
                                                                        <div className="form-group row">
                                                                            <label className="col-sm-4 col-form-label">Phone Number{editMode ? <span className="red">*</span> : ''}</label>
                                                                            <div className="col-sm-8">
                                                                                <input
                                                                                    type="text"
                                                                                    id="phonenumber"
                                                                                    data-testid="phonenumber"
                                                                                    autoComplete="off"
                                                                                    className={`form-control ${formErrors && formErrors.phonenumber ? 'has-error' : ''}`}
                                                                                    maxLength="10"
                                                                                    value={editObj && editObj.phonenumber ? editObj.phonenumber : ''}
                                                                                    onChange={(e) => handleInputChange('phonenumber', e.target.value)}
                                                                                />
                                                                                {formErrors && formErrors.phonenumber ?
                                                                                    <div className="customer-error-msg">
                                                                                        <p>{formErrors.phonenumber}</p>
                                                                                    </div>
                                                                                    : ''}
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                    <div className="form-group row mt-5">
                                                                        <div className="col-sm-12 text-end mt-5">
                                                                            {!editMode ? <button type="button" data-testid="edit-btn" id="edit-btn" className="btn btn-primary" onClick={toggleEditMode}>Edit</button> : ''}
                                                                            {editMode ?
                                                                                <>
                                                                                    <button type="button" id="save-btn" className="btn btn-primary" disabled={disableSubmitBtn} onClick={saveCustomer}>Save</button>
                                                                                    <button type="button" id="cancel-btn" className="btn btn-secondary ms-2" onClick={this.cancelEdit}>Cancel</button>
                                                                                </>
                                                                                : ''}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className={`case-details-container ${showSurgeonsBlock ? '' : 'shrink'}`}>
                                                    <div className="card-body mt-4">
                                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                                            <h2 className="card-title pb-2 mb-0">Associated Surgeons</h2>
                                                            <div className="btn btn-primary" data-testid="openSurgeonModal" id="openSurgeonModal" onClick={() => enableAddSurgeonModal(true)}>Add New</div>
                                                        </div>
                                                        {showSurgeonsBlock ?
                                                            <>
                                                                {surgeons.length ?
                                                                    <div className="customer-surgeon-list table-wrap">
                                                                        <SurgeonList listType="customer-surgeons" columns={columns} showPagination={false} disableColmSort={false} searchData={surgeons} customer={customer} refreshSurgeon={refreshSurgeon} refreshDataList={false} />
                                                                    </div>
                                                                    :
                                                                    <div className="row">
                                                                        <div className="col-sm-12"><ErrorMsgBlockComponent noData={true} /></div>
                                                                    </div>}
                                                                {this.getShowTextTemplate('surgeon', 'Less')}
                                                            </>
                                                            : this.getShowTextTemplate('surgeon', 'More')}
                                                    </div>
                                                </div>

                                                <div className={`case-details-container ${showCareTeamBlock ? '' : 'shrink'}`}>
                                                    <div className="card-body mt-4">
                                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                                            <h2 className="card-title pb-2 mb-0">Associated Care team member</h2>
                                                            <div className="btn btn-primary" data-testid="openCareTeamModal" id="openCareTeamModal" onClick={() => enableCareTeamModal(true)}>Add New</div>
                                                        </div>
                                                        {showCareTeamBlock ?
                                                            <>
                                                                {!careTeamListloading ?
                                                                    <div className="customer-surgeon-list table-wrap">
                                                                        <SurgeonList
                                                                            listType="careteam-customers"
                                                                            columns={careteamColumns}
                                                                            showPagination={false}
                                                                            disableColmSort={false}
                                                                            searchData={careteamList}
                                                                            customer={customer}
                                                                            error={careTeamError}
                                                                            refreshCareteamList={refreshCareteamList}
                                                                            refreshDataList={false}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="row">
                                                                        <div className="col-sm-12"><ErrorMsgBlockComponent noData={true} /></div>
                                                                    </div>
                                                                }
                                                                {this.getShowTextTemplate('careTeam', 'Less')}
                                                            </>
                                                            : this.getShowTextTemplate('careTeam', 'More')}
                                                    </div>
                                                </div>

                                                <div className={`case-details-container ${ showAssociatedSalesRepBlock ? "" : "shrink" }`} >
                                                <div className="card-body mt-4">
                                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                                    <h2 className="card-title pb-2 mb-0">
                                                        Associated Sales Rep
                                                    </h2>
                                                    </div>
                                                    {showAssociatedSalesRepBlock ? (
                                                    <>
                                                        {associatedSalesRepList?.length &&
                                                        !associateSalesRepLoading ? (
                                                        <div className="customer-associate-sales-rep-list table-wrap">
                                                            <SurgeonList
                                                            listType="customer-associate-sales-rep"
                                                            columns={associatedSalesRepColumns}
                                                            showPagination={true}
                                                            disableColmSort={false}
                                                            searchData={associatedSalesRepList}
                                                            error={associateSalesRepError}
                                                            />
                                                        </div>
                                                        ) : (
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                            <ErrorMsgBlockComponent noData={true} />
                                                            </div>
                                                        </div>
                                                        )}
                                                        {this.getShowTextTemplate(
                                                        "associatedSalesRep",
                                                        "Less"
                                                        )}
                                                    </>
                                                    ) : (
                                                    this.getShowTextTemplate(
                                                        "associatedSalesRep",
                                                        "More"
                                                    )
                                                    )}
                                                </div>
                                                </div>

                                                <div className={`case-details-container ${showDeviceBlock ? '' : 'shrink'}`}>
                                                <CustomerDevice
                                                    id="device-container"
                                                    refreshDeviceList={this.state.refreshDeviceList}
                                                    customerid={customer.customerid}
                                                    deviceAdded={this.deviceAdded}
                                                    showSuccessMsg={this.state.showDeviceAddSuccessMsg}
                                                    showDeviceBlock={showDeviceBlock}
                                                    isCustomerDisable={true}
                                                    togglePanels={this.togglePanels}
                                                />
                                                </div>

                                                <div className={`case-details-container ${showLicenseBlock ? '' : 'shrink'}`}>
                                                    <div className="card-body mt-4">
                                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                                            <h2 className="card-title pb-2 mb-0">License</h2>
                                                            <div className="btn btn-primary" data-testid="openLicenseModal" id="openLicenseModal" onClick={() => enableAddLicenseModal(true)}>Add New</div>
                                                        </div>

                                                        {showLicenseBlock ?
                                                            <>
                                                                {licenseSuccessMsg ?
                                                                    <div className="license-save-success-msg">
                                                                        <p>{licenseSuccessMsg}</p>
                                                                    </div>
                                                                    : ''}

                                                                {!licenseListloading ?
                                                                    <div className="customer-license-list">
                                                                        <SurgeonList
                                                                            listType="customer-License"
                                                                            columns={licenseColumns}
                                                                            showPagination={false}
                                                                            disableColmSort={false}
                                                                            searchData={licenseList}
                                                                            error={licenseError}
                                                                            refreshLicenseDataList={refreshLicenseDataList}
                                                                            refreshDataList={false}
                                                                        />
                                                                    </div>
                                                                    : ''}
                                                                {this.getShowTextTemplate('license', 'Less')}
                                                            </>
                                                            : this.getShowTextTemplate('license', 'More')}
                                                    </div>
                                                </div>
                                            </div>

                                            : customerError ? <ErrorMsgBlockComponent errorObject={customerError} /> : <ErrorMsgBlockComponent noData={true} />}
                                        <div className="add-surgeon-modal">
                                            {/* Modal to add surgeon to mapping list */}
                                            <CustomModal
                                                id="addSurgeonModal"
                                                data-testid="addSurgeonModal"
                                                dialogClassName="add-surgeon-dialog"
                                                show={showAddSurgeonModal}
                                                isComponent={true}
                                                isAlert={true}
                                                alertText="Close"
                                                title="Add Surgeon"
                                                closeAction={() => enableAddSurgeonModal(false)}>
                                                <AddSurgeon
                                                    handleInputChange={handleSearchInputChange}
                                                    isLoading={loading && !searchResults}
                                                    onEnterPress={onSearchEnterPress}
                                                    action="adminCustomerSurgeonMap"
                                                    findSurgeons={findSurgeons}
                                                    searchError={searchError}
                                                    formErrors={formErrors}
                                                    searchResults={searchResults}
                                                    refreshDataList={refreshDataList}
                                                    customer={customer}
                                                />
                                            </CustomModal>
                                        </div>
                                        <div className="add-surgeon-modal">
                                            {/* Modal to add surgeon to mapping list */}
                                            <CustomModal
                                                id="addCareteamModal"
                                                data-testid="addCareteamModal"
                                                dialogClassName="add-surgeon-dialog"
                                                show={showAddCareteamModal}
                                                isComponent={true}
                                                isAlert={true}
                                                alertText="Close"
                                                title="Add Care Team Member"
                                                closeAction={() => enableCareTeamModal(false)}>
                                                <AssociatedCareteam
                                                    handleInputChange={handleSearchInputChange}
                                                    isLoading={loading && !searchResultCareteam}
                                                    onEnterPress={onSearchEnterPress}
                                                    action="adminCustomerCareteamMap"
                                                    findCareTeam={findCareTeam}
                                                    searchError={searchError}
                                                    formErrors={formErrors}
                                                    searchResults={searchResultCareteam}
                                                    refreshDataList={refreshCareteamList}
                                                    customer={customer}
                                                />
                                            </CustomModal>
                                        </div>
                                        <div className="add-license-modal">
                                            {/* Modal to add new license */}
                                            <CustomModal
                                                id="addNewLicense"
                                                data-testid="addNewLicense"
                                                dialogClassName="add-license-dialog"
                                                show={showAddLicenseModal}
                                                isComponent={true}
                                                title="Add License"
                                                closeAction={() => enableAddLicenseModal(false)}>
                                                <AddLicense
                                                    id="addLicenseComponent"
                                                    handleInputChange={handleSearchInputChange}
                                                    isLoading={false}
                                                    formErrors={formErrors}
                                                    refreshLicenseDataList={refreshLicenseDataList}
                                                    customerId={customer && customer.customerid}
                                                    cancelAction={() => enableAddLicenseModal(false)}
                                                />
                                            </CustomModal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
