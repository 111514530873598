import React, { Component } from 'react';
import CustomModal from '../../shared/CustomModal';
import { salesRepMessages } from '../../helpers/messages';

export default class ZipPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otp: Array(6).fill(''),
            error: Array(6).fill(false),
            validationError: '',
        };

        this.otpInputRefs = Array(6)
            .fill()
            .map(() => React.createRef());
        this.submitButtonRef = React.createRef();
    }

    /**
     * @description function to handle change in the input fields
     * @param {number} elementIndex - the index of the input field
     * @memberof ZipPassword
     */
    handleInputChange = (elementIndex) => (event) => {
        if (event.target.value === '' || /^[0-9\b]+$/.test(event.target.value)) {
            const otp = [...this.state.otp];
            otp[elementIndex] = event.target.value;
            const error = [...this.state.error];
            error[elementIndex] = event.target.value === '';
            this.setState({ otp, error }, () => {
                 /* istanbul ignore next  */
                if (event.target.value !== '' && elementIndex < this.otpInputRefs.length - 1) {
                    this.otpInputRefs[elementIndex + 1].current.focus();
                }
                 /* istanbul ignore next  */
                if (this.state.otp.join('').length === 6) {
                    this.setState({ validationError: '' });
                    this.submitButtonRef.current.focus();
                }
            });
        }
    };

    /**
     * @description function to handle key down events
     * @param {number} elementIndex - the index of the input field
     * @memberof ZipPassword
     */
    handleKeyDown = (elementIndex) => (event) => {
        /* istanbul ignore next  */
        if (event.keyCode === 8 && this.state.otp[elementIndex] === '' && elementIndex > 0) {
            const otp = [...this.state.otp];
            otp[elementIndex - 1] = '';
            const error = [...this.state.error];
            error[elementIndex - 1] = false;
            this.setState({ otp, error }, () => {
                this.otpInputRefs[elementIndex - 1].current.focus();
            });
        } else if (event.keyCode === 13) {
            this.handleSubmit(event);
        } else if (event.keyCode === 9 && this.state.otp[elementIndex] === '') {
            const error = [...this.state.error];
            error[elementIndex] = true;
            this.setState({ error });
            event.preventDefault();
        }
    };

    /**
     * @description function to handle form submission
     * @memberof ZipPassword
     */
     /* istanbul ignore next  */
    handleSubmit = (e) => {
        e.preventDefault();
        
        const password = this.state.otp.join('');
        if (password.length === 6) {
            this.props.onPSubmit(password);
            this.setState({ otp: Array(6).fill(''), error: Array(6).fill(false), validationError: '' });
        } else {
            const error = this.state.otp.map((digit) => digit === '');
            this.setState({
                validationError: salesRepMessages.passwordError,
                error,
            });
            return;
        }
        /* istanbul ignore next  */
        setTimeout(() => {
            this.setState({ otp: Array(6).fill(''), error: Array(6).fill(false), validationError: '' });
            this.props.hideModal();
        }, 500);
    };

    /**
     * @description function to handle modal close action
     * @memberof ZipPassword
     */
    handleModal = () => {
        this.setState({ otp: Array(6).fill(''), error: Array(6).fill(false), validationError: '' });
        this.props.hideModal();
    };

    /**
     * @description function to render the component
     * @memberof ZipPassword
     */
    render() {
        const { isPVisible, loading } = this.props;
        const { otp, error, validationError } = this.state;

        return (
            <div>
                <CustomModal show={isPVisible} dialogClassName=' border  rounded' isComponent={true} title='Please create a transfer key.' closeAction={this.handleModal}>
                    <div className='m-auto'>
                        <form onSubmit={this.handleSubmit}>
                            <div className='form-group'>
                                <label className='pb-3 passcode-label'>This key will be used to confirm data transfer to the CORI system.</label>
                                <div className='d-flex pb-4 flex-wrap gap-2'>
                                    {otp.map((digit, index) => (
                                        <input
                                            type='text'
                                            key={index}
                                            disabled={loading}
                                            className={`form-control  passcode-input ${error[index] ? 'is-invalid' : ''}`}
                                            maxLength='1'
                                            ref={this.otpInputRefs[index]}
                                            value={digit}
                                            onChange={this.handleInputChange(index)}
                                            onKeyDown={this.handleKeyDown(index)}
                                            data-testid="otpInput"
                                        />
                                    ))}
                                </div>
                                {validationError && <div className='invalid-feedback d-block'>{validationError}</div>}
                            </div>

                            <div className='d-flex gap-2 mt-3'>
                                <button data-testid="submit" type='submit' className='btn btn-primary' ref={this.submitButtonRef} disabled={loading || otp.join('').length < 6}>
                                    {loading ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> : <>Submit</>}
                                </button>
                                <button data-testid="close" type='button' className='btn btn-primary' onClick={this.handleModal}>
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>
                </CustomModal>
            </div>
        );
    }
}
