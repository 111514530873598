import React, { Component } from 'react';
import { AssociatedCustomerList } from './AssociatedCustomerList';
import { loggerEventOutcome, loggerEventTypes, loggerEventName,customerMapping } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
import { getImgSrc } from '../../helpers/GlobalFunctions';
let startDate;

class AssociateCustomer extends Component {
    constructor() {
        super()
        this.state = {
            loggerObj: {
                "EventOutcome": loggerEventOutcome.success,
                "EventType": loggerEventTypes.read,
                "EventName": loggerEventName.associateSurgeonToCustomer,
                "StartDate": new Date()
            }
        }
    }

    componentDidMount() {
        // initialize the start date on page load
        startDate = new Date();
        window.addEventListener('beforeunload', this.applicationLogger);
    }

    /* istanbul ignore next  */
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.applicationLogger);
        this.applicationLogger();
    }

    /**
     * @description function to handle application logs
     * @param {*}
     * @memberof FilesListContainer
     */
    /* istanbul ignore next  */
    applicationLogger(key) {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.associateSurgeonToCustomer,
            "Content": {
                "TimeSpent": timeSinceLoad
            }
        }
        logger(loggerObj);
    }
    render() {
        const { handleInputChange, findCustomers, formErrors, searchResults, searchError, isLoading, refreshDataList, onEnterPress, action, surgeonUsername } = this.props;
        /* istanbul ignore next  */
        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                Filter: ''
            },
            {
                Header: 'Address',
                accessor: 'combinedAddress',
                Filter: ''
            },
            {
                Header: 'Phone No',
                accessor: 'phoneNumber',
                Filter: ''
            },
            {
                Header: 'Customer ID',
                accessor: 'customerid',
                Filter: ''
            },
            {
                Header: ' ',
                SearchAction: true
            }
        ]
        return (<div>
            <div className="surgeon-search-container">
                <div className="row col-md-12">
                    <div className="col-md-8">
                        <input placeholder={customerMapping.emptySearchBox} className="form-control" onKeyPress={onEnterPress} type="text" data-testid="customerSearchKey" id="customerSearchKey" autoComplete="off" onChange={(e) => handleInputChange(e.target.value)} />
                        {formErrors && formErrors.searchKey ?
                            <div className="proms-error-msg">
                                <p>{formErrors.searchKey}</p>
                            </div>
                            : ''}
                    </div>
                    <div className="col-md-4">
                        <svg onClick={findCustomers} width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-search serach-icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
                            <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="surgeon-search-results">
                {searchResults && isLoading === false ? <AssociatedCustomerList listType="customer-search" action={action} error={searchError} surgeonUsername={surgeonUsername ? surgeonUsername : ''} columns={columns} showPagination={false} disableColmSort={false} searchData={searchResults} refreshDataList={refreshDataList} /> : null}
                {isLoading ? <div className="loading-img"><img src={getImgSrc('loading.gif')} alt="loading" /> </div> : null}
            </div>
        </div>);
    }
}
export default AssociateCustomer;

